import React, { useEffect, useRef, useState } from "react";
import unpaidInvoiceData from "../../../../Json/Invoice/unpaid-invoice.json";
import DataTable from "react-data-table-component";
import { Card, Form, Button, Badge } from "react-bootstrap";
import AddPaymentDetails from "./add-payment-modal";
import Header from "../../../../layouts/Header";
import { GetInvoiceData } from "../../../../application/actions/financeTimeSheetAction";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceDataListById } from "../../../../application/selectors/indexSelector";
import useAuth from "../../../../hooks/useAuth";
import LoadingBar from "react-top-loading-bar";
import ShimmerLoader from "../../../_common/ShimmerLoader";

export default function FinanceManagerUnpaidInvoice() {
  const { setAuth, auth } = useAuth();
  const dispatch = useDispatch();
  const Invoicedata = useSelector(getInvoiceDataListById);
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "getUnpaidList",
  });
  useEffect(() => {
    const data = {
      UserId: auth?.id,
      StatusId: "a4298b8d-3631-4899-a105-ad7ac94a9ff5",
    };
    dispatch(GetInvoiceData(data));
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading({ isLoading: true, value: "getUnpaidList" });
        const data = {
          UserId: auth?.id,
          StatusId: "a4298b8d-3631-4899-a105-ad7ac94a9ff5",
        };
        await Promise.all([
          dispatch(GetInvoiceData(data))
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading({ isLoading: false, value: "" });
      }
    };
  
    fetchData();
  }, [auth?.id, dispatch, setLoading]);
  


  const columns = [
    {
      name: "InvoiceNo",
      selector: (row) => row?.invoiceNo,
      sortable: true,
      cell: (row) => (
        <a style={{color:"blue"}} onClick={() => handleAddPaymentDetails(true, row)} target="_blank" rel="noopener noreferrer">
          {row?.invoiceNo}
        </a>
      ),
    },

    {
      name: "Client / Subs",
      selector: (row) => row?.clienName,
      sortable: true,
    },

    {
      name: "Month",
      selector: (row) => row?.months,
      sortable: true,
    },
    {
      name: "Payment Status",
      selector: (row) => (
        <Badge bg={row?.fieldValue === "UnPaid" ? "warning" : "success"} pill>
          {row?.fieldValue}
        </Badge>
      ),
    },
    {
      name: "Invoice Amount Code",
      selector: (row) => row?.invoiceAmount,
      sortable: true,
    },
    {
      name: "Submission Date",
      selector: (row) => row?.duedate,
      sortable: true,
    },


    {
      name: "Add Payment Details",
      selector: (row) => (
        <div className="d-flex align-items-center gap-2">
          <Button variant="outline-dark" onClick={() => handleAddPaymentDetails(true, row)}>Add Payment Details</Button>
        </div>
      ),
      sortable: true,
    },
  ];

  // SEARCH FILTER TABLE DATA
  const [searchTerm, setSearchTerm] = useState('');
  const filteredUnpaidInvoice = unpaidInvoiceData?.filter((item) =>
    Object.values(item).some((value) =>
      value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const [showAddPaymentDetails, setShowAddPaymentDetails] = useState(false);
  const [setAddPaymentDetails, seAddPaymentDetails] = useState(false);
  const handleAddPaymentDetails = (state, data) => {
    setShowAddPaymentDetails(state);
    seAddPaymentDetails(data);
  };

   // PROGRESS TOP LOADER
   const loadingBarRef = useRef(null);
   useEffect(() => {
     if (loadingBarRef.current) {
       // Start the loading bar
       loadingBarRef.current.continuousStart();
 
       // Simulate a load completion or manage dynamically
       const timer = setTimeout(() => {
         loadingBarRef.current.complete();
       }, 3000); // Adjust timing as needed
 
       // Cleanup timer
       return () => clearTimeout(timer);
     }
   }, []);

  return (
    <React.Fragment>
      <Header />
      <AddPaymentDetails show={showAddPaymentDetails} closeFunction={handleAddPaymentDetails} data={setAddPaymentDetails} />
      <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-3">
          <h3 className="main-title mb-0">Unpaid Invoice</h3>
        </div>
        <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
        <Card className="card-one">
          <Card.Header className="align-items-center justify-content-between">
            <h4 className="main-title fs-14 mb-0">List of Unpaid Invoice</h4>
            <div className="custom-dropdown-wrapper">
              <div className="custom-drop-down z-index-2 wt-300">
                <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                <Form.Control
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </Card.Header>
          <Card.Body>
          {loading.isLoading ? (
              <ShimmerLoader colNames={columns.map((col) => col.name)} colsCount={columns.length} />
            ) : (
            <DataTable
              columns={columns}
              data={Invoicedata}
              fixedHeader
              search={true}
              highlightOnHover
              pagination
            ></DataTable>
          )}
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  )
}