import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AddNewUserDirectory from "./add-user-directory";
import UserDirectoryDetails from "./user-directory-detail";
import { useDispatch, useSelector } from "react-redux";
import { getUserDirectoryData } from "../../../../application/selectors/indexSelector";
import { GetUserDirectory } from "../../../../application/actions/usersAction";

export default function UserDirectory() {
    const [showAddUserDirectory, setShowAddUserDirectory] = useState(false);
    const dispatch = useDispatch()
    const GetUserGrouplist = useSelector(getUserDirectoryData);
    useEffect(() => {
        dispatch(GetUserDirectory);
    }, []);

    const columns = [
        {
            name: "Full Name",
            selector: (row) => row.fullName,
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: "User Role",
            selector: (row) => row.roleName,
            sortable: true,
        },
        {
            name: "DOB",
            selector: (row) => row.dob,
        },
        {
            name: "Status",
            selector: (row) => (
                <Badge className={`${row.status === "Active" ? "bg-success" : ""} ${row.status === "Inactive" ? "bg-danger" : ""}`} pill>
                    {row.status}
                </Badge>
            ),
            sortable: true,
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex justify-content-end">
                    <Button
                        variant="secondary"
                        className="btn-icon"
                        onClick={() => handleDirectoryDetails(row)}>
                        <i className="ri-eye-line"></i>
                    </Button>
                </div>
            ),
        },
    ];

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredEmployees = GetUserGrouplist?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const handleAddUserDirectory = (state) => {
        setShowAddUserDirectory(state);
    };

    const [userData, setUserData] = useState(null);
    const [showDirectoryDetails, setShowDirectoryDetails] = useState(false);
    const handleDirectoryDetails = (GetUserGrouplist) => {
        setUserData(GetUserGrouplist); // Set the row data
        setShowDirectoryDetails(true); // Show the UserDirectoryDetails component
    };

    return (
        <React.Fragment>
            <AddNewUserDirectory show={showAddUserDirectory} closeFunction={handleAddUserDirectory} />
            <UserDirectoryDetails show={showDirectoryDetails} closeFunction={() => setShowDirectoryDetails(false)} data={userData} />
            <Card className="card-one">
                <Card.Header className="align-items-center justify-content-between">
                    <h4 className="main-title fs-14 mb-0">List of User Directory</h4>
                    <div className="d-flex gap-3">
                        <div className="custom-dropdown-wrapper">
                            <div className="custom-drop-down z-index-2 wt-300">
                                <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                                <Form.Control
                                    type="text"
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>

                        <div>
                            <Button variant="primary" className="d-flex align-items-center gap-2" onClick={() => handleAddUserDirectory(true)}>
                                <i className="ri-add-line fs-18 lh-1"></i>
                                <span className="d-sm-inline"> Add New User Directory</span>
                            </Button>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <DataTable
                        columns={columns}
                        data={filteredEmployees}
                        fixedHeader
                        search={true}
                        highlightOnHover
                        pagination
                    ></DataTable>
                </Card.Body>
            </Card>
        </React.Fragment>
    );
}
