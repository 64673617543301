import React, { useEffect, useState } from "react";
import { Offcanvas, Card, Row, Col, ListGroup, Button, Form, Badge } from "react-bootstrap";

export default function DetailSubscription(props) {
    const [subscriptionData, setSubscriptionData] = useState(null);
    useEffect(() => {
        if (props.data) {
            setSubscriptionData(props.data);
        }
    }, [props.data]);

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-80">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Subscription Details</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {subscriptionData ? (
                        <Row>
                            <Col md={8}>
                                <Card className="card-one">
                                    <Card.Header>
                                        <Card.Title>Basic Info</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className="g-3">
                                            <Col md={4}>
                                                <Form.Label className="fs-12 mb-0">Name</Form.Label>
                                                <h6 className="fw-semibold text-dark">{subscriptionData.subsName}</h6>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label className="fs-12 mb-0">Contract Type</Form.Label>
                                                <h6 className="fw-semibold text-dark">{subscriptionData.contractName}</h6>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label className="fs-12 mb-0">Start Date</Form.Label>
                                                <h6 className="fw-semibold text-dark">{subscriptionData.startDate}</h6>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label className="fs-12 mb-0">Term Date</Form.Label>
                                                <h6 className="fw-semibold text-dark">{subscriptionData.endDate}</h6>
                                            </Col>

                                            <Col md={4}>
                                                <Form.Label className="fs-12 mb-0">Billing Status</Form.Label>
                                                <h6 className="fw-semibold text-danger">
                                                    <Badge bg={subscriptionData.billableStatus === "Non-Billable" ? "danger" : "success"} pill>{subscriptionData.billableStatus}</Badge>
                                                </h6>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label className="fs-12 mb-0">Monthly Minimum</Form.Label>
                                                <h6 className="fw-bold text-dark">{subscriptionData.monthlyMinimum}</h6>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label className="fs-12 mb-0">Operations Reviewer</Form.Label>
                                                <h6 className="fw-semibold text-danger">
                                                    {subscriptionData.reviewer ? (
                                                        subscriptionData.reviewer.map((reviewer, index) => (
                                                            <Badge key={index} bg="primary" pill>{reviewer}</Badge>
                                                        ))
                                                    ) : (
                                                        <span>No Reviewer</span>
                                                    )}
                                                </h6>
                                            </Col>

                                            <Col md={4}>
                                                <Form.Label className="fs-12 mb-0">Operations Approver</Form.Label>
                                                <h6 className="fw-semibold text-danger">
                                                    {subscriptionData.approver ? (
                                                        subscriptionData.approver.map((approver, index) => (
                                                            <Badge key={index} bg="primary" pill>{approver}</Badge>
                                                        ))
                                                    ) : (
                                                        <span>No Approver</span>
                                                    )}
                                                </h6>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label className="fs-12 mb-0">Finanace Manager</Form.Label>
                                                <h6 className="fw-semibold text-danger">
                                                    {subscriptionData.manager ? (
                                                        subscriptionData.manager.map((manager, index) => (
                                                            <Badge key={index} bg="primary" pill>{manager}</Badge>
                                                        ))
                                                    ) : (
                                                        <span>No Finanace Manager</span>
                                                    )}
                                                </h6>
                                            </Col>

                                            <Col md={4}>
                                                <Form.Label className="fs-12 mb-0">Status</Form.Label>
                                                <h6 className="fw-semibold text-danger">
                                                    <Badge bg="success" pill>Active</Badge>
                                                </h6>
                                            </Col>

                                            <Col md={4}>
                                                <Form.Label className="fs-12 mb-0">Created By</Form.Label>
                                                <div>
                                                    {subscriptionData.createdBy ? (
                                                        <h6 className="fw-semibold text-danger">{subscriptionData.createdBy}</h6>
                                                    ) : (
                                                        <span>--</span>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4}>
                                <Card className="card-one h-auto">
                                    <Card.Header>
                                        <Card.Title>PDF File</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <ListGroup as="ul" className="list-group-one">
                                            {[
                                                {
                                                    "name": "Report_2024_Q1.pdf",
                                                    "description": "Sample Data",
                                                },
                                                {
                                                    "name": "Project_Plan.pdf",
                                                    "description": "Sample Data",
                                                },
                                                {
                                                    "name": "Annual_Report.pdf",
                                                    "description": "Sample Data",
                                                },
                                                {
                                                    "name": "Invoice_12345.pdf",
                                                    "description": "Sample Data",
                                                },
                                                {
                                                    "name": "Project_Plan.pdf",
                                                    "description": "Sample Data",
                                                },
                                                {
                                                    "name": "sample.pdf",
                                                    "description": "Sample Data",
                                                }
                                            ].map((item, index) => (
                                                <ListGroup.Item key={index} as="li" className="px-0 d-flex align-items-center justify-content-between gap-2">
                                                    <div><i className="ri-file-pdf-line fs-22 align-middle"></i>{" "} <span>{item.name}</span></div>
                                                    <div><Button variant="outline-dark" className="btn-icon"><i className="ri-download-line"></i></Button></div>
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    ) : (
                        <p>No data available.</p>
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    )
};