import React, { useEffect, useState, useRef } from "react";
import { Badge, Button, Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import subscriptionData from "../../../Json/Setting/subscription-master.json";
import CreateSubscriptionMaster from "./create-sub-master";
import SweetAlertService from "../../_common/SweetAlertService";
import ToastifyService from "../../_common/ToastifyService";
import { useDispatch, useSelector } from "react-redux";
import { DeleteMasterSub, GetMasterSub } from "../../../application/actions/subscriptionAction";
import { getMasterSubscrtiptionData } from "../../../application/selectors/indexSelector";
import LoadingBar from "react-top-loading-bar";

export default function SubscriptionMaster() {
    const dispatch = useDispatch();
    const [showCreateSubMaster, setShowCreateSubMaster] = useState(false);
    const handleCreateSubMaster = (state) => {
        setShowCreateSubMaster(state);
    }
    const MasterList = useSelector(getMasterSubscrtiptionData);
    useEffect(() => {
          dispatch(GetMasterSub);
          dispatch(DeleteMasterSub());
      }, []);

      const[fieldvalue, setFieldValue]= useState(null)
      const handleUpdate =(data)=>{
          setFieldValue(data)
          setShowCreateSubMaster(true);
      }
      

    const columns = [
        {
            name: "Subscription Name",
            selector: (row) => row.subsName,
            sortable: true,
        },
        {
            name: "Status",
            selector: (row) => (
                <Badge bg={row.status === "Active" ? "success" : "danger"} pill>{row.status}</Badge>
            ),
            sortable: true,
        },
       
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex justify-content-end">
                    <Button variant="primary" className="btn-icon me-2" onClick={() => handleUpdate(row)}>
                        <i className="ri-pencil-line"></i>
                    </Button>
                    <Button variant="outline-danger" className="btn-icon" onClick={() => handleDelete(row)}>
                        <i className="ri-delete-bin-line"></i>
                    </Button>
                </div>
            ),
            width: "200px"
        },
    ];

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredEmployees = subscriptionData?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

 

    // HANDLE DELETE FUNCTION
    const handleDelete = (rowData) => {       
        SweetAlertService.showAlert(
            rowData.subsName, // Assuming `subsName` holds the name of the subscription
            "Are you sure you want to delete this " + rowData.subsName + "",
            "warning"
        )
            .then((result) => {
                if (result.isConfirmed) {
                    dispatch(DeleteMasterSub(rowData.id)); // Pass the correct ID here
                    ToastifyService.success(rowData.subsName + ` Deleted Successfully`);
                    
                    
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                ToastifyService.error(`Something went wrong`);
            });
    };

    // PROGRESS TOP LOADER
    const loadingBarRef = useRef(null);
    useEffect(() => {
        if (loadingBarRef.current) {
            // Start the loading bar
            loadingBarRef.current.continuousStart();

            // Simulate a load completion or manage dynamically
            const timer = setTimeout(() => {
                loadingBarRef.current.complete();
            }, 3000); // Adjust timing as needed

            // Cleanup timer
            return () => clearTimeout(timer);
        }
    }, []);
    
    return (
        <React.Fragment>
            <CreateSubscriptionMaster show={showCreateSubMaster} closeFunction={handleCreateSubMaster} data={fieldvalue}/>
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="fs-14">Subscription Master</h4>
                </div>
                <div className="d-flex gap-2 mt-3 mt-md-0">
                    <Button variant="primary" className="d-flex align-items-center gap-2" onClick={() => handleCreateSubMaster(true)}>
                        <i className="ri-add-line fs-18 lh-1"></i><span className="d-sm-inline">Create Subscription Master</span>
                    </Button>
                </div>
            </div>

            <Card className="card-one">
                <Card.Header className="align-items-center justify-content-between">
                    <h4 className="main-title fs-14 mb-0">List of Subscription Master</h4>
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-300">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <DataTable
                        columns={columns}
                        data={MasterList}
                        fixedHeader
                        search={true}
                        highlightOnHover
                        pagination
                    ></DataTable>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}