import React, { useState, useEffect } from "react";
import { Button, Form, Offcanvas, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { format, subDays } from "date-fns";
import {
  AddDayWiseDataById,
  GetContincyListById,
  GetMappedContractTypeById,
  GetTimeSheetDayWiseBYId,
} from "../../../../../application/actions/timeSheetAction";
import ToastifyService from "../../../../_common/ToastifyService";
import { useDispatch, useSelector } from "react-redux";
import {
  getContincyListBy,
  getContractTypesById,
  getDayWiseListById,
} from "../../../../../application/selectors/indexSelector";
import { useNavigate } from "react-router-dom";
import {
  AddFinanceTimeSheetStatus,
  AddInvoice,
} from "../../../../../application/actions/financeTimeSheetAction";
import Template4 from "../../../../Invoicetemplate/TemplatesDesign/template-4/template-4";
import Swal from "sweetalert2";
import useAuth from "../../../../../hooks/useAuth";

export default function MonthsviewModal(props) {
  const { setAuth, auth } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [gettimeSheetData, setGettimeSheetData] = useState(null);
  const [showCreateTemplate, setShowCreateTemplate] = useState(false);
  const [data, setData] = useState([]);
  const [editId, setEditId] = useState(null);
  const [forumla, setFormula] = useState(null);
  const [amount, setAmount] = useState(null);
  const [editedRow, setEditedRow] = useState({});
  const [editedata, setEditedata] = useState({});
  const [remarks, setRemarks] = useState("");
  const DayWiseTimeSheetList = useSelector(getDayWiseListById);
  const ContractTypes = useSelector(getContractTypesById);
  const [initialFetchData, setInitialFetchData] = useState(null);
    const ContincyList = useSelector(getContincyListBy);
  

  useEffect(() => {
    if (props.show) {
      if (props.data) {
        setGettimeSheetData(props.data);
        setData(props.data.months || "");

        const data = {
          Id: props?.data?.clientId,
          SubsId: props?.data?.subscriptionId,
          monthId: props?.data?.months,
        };
        setInitialFetchData(data);
        dispatch(GetTimeSheetDayWiseBYId(data));
        dispatch(GetMappedContractTypeById(data));
        dispatch(GetContincyListById(props?.data?.subscriptionId));
      }
    }
  }, [props.show, props.data]);

const [totalAmount, setTotalAmount] = useState(0);
  const [baseAmount, setBaseAmount] = useState(0);

useEffect(() => {
  // Calculate total amount first
  const calculatedTotalAmount = DayWiseTimeSheetList.reduce((acc, item) => {
    return acc + (Math.round(item?.invoiceAmount) || 0);
  }, 0);

  setTotalAmount(calculatedTotalAmount); // Set the total amount in state

  // Now, calculate base amount based on matching element and contract logic
  if (calculatedTotalAmount !== 0) {
    let calculatedBaseAmount = 0;

    // Find the matching element for Contingency Amount calculation
    if (props?.data?.contractName === "Contingency Amount") {
      const matchingElement = ContincyList?.find(
        (element) =>
          calculatedTotalAmount >= element.from && calculatedTotalAmount <= element.to
      );

      // If a matching element is found, calculate the baseAmount based on percentage
      if (matchingElement) {
        calculatedBaseAmount = calculatedTotalAmount * (matchingElement.percentage / 100);
      } else {
        // Apply default percentage if no match is found
        calculatedBaseAmount = calculatedTotalAmount * (10 / 100); // Default 10% if no match
      }
    } else {
      // For other contract types, base amount is simply totalAmount (or apply other logic)
      if (props?.data?.contractName === "Transaction") {
        calculatedBaseAmount = calculatedTotalAmount * 1; // For example, 100% of totalAmount
      } else if (props?.data?.contractName === "FTE Amount") {
        calculatedBaseAmount = calculatedTotalAmount * 1; // For example, 100% of totalAmount
      } else if (props?.data?.contractName === "FlatFee") {
        // Add the flat fee
        DayWiseTimeSheetList.forEach((item) => {
          calculatedBaseAmount += item?.flatFee || 0;
        });
      }
    }

    setBaseAmount(calculatedBaseAmount); // Set the calculated base amount in state
  }

  // Optionally, you can log or perform further actions with `totalAmount` and `baseAmount`
  console.log("Total Amount:", totalAmount);
  console.log("Base Amount:", baseAmount);

}, [DayWiseTimeSheetList, props?.data?.contractName]); // Add rowData?.contractName as a dependency


  useEffect(() => {
    if (props.show) {
      if (props.data) {
        switch (props?.data?.contractName) {
          case "Transaction Amount":
            setFormula("transactions");
            setAmount(ContractTypes?.transactionRate);
            break;
          case "FTE Amount":
            setFormula("transactions");
            setAmount(ContractTypes?.fteRate);
            break;
          case "Contingency Amount":
            setFormula("clientCollections");
            break;
          case "flatefee":
            setFormula("flatefee");
            break;
          default:
            setFormula(null);
        }
      }
    }
  }, [
    props.show,
    props.data,
    ContractTypes?.transactionRate,
    ContractTypes?.fteRate,
  ]);

  const handleEditClick = (row) => {
    setEditId(row.id);
    setEditedRow({ ...row });
  };

  const handleSaveClick = async () => {
    const data1 = {
      id: editedRow.id,
      transactions: editedRow.transactions || 0,
      ClientCollections: editedRow.ClientCollections || 0,
      FTEDeployed: editedata.FTEDeployed || 0,
      TransactionAmount: editedata.TransactionAmount || 0,
      FTEAmount: editedata.FTEAmount || 0,
      ContingencyAmount: editedata.ContingencyAmount || 0,
      InvoiceAmount: editedata.invoiceAmount || 0,
    };

    try {
      dispatch(AddDayWiseDataById({ data1, initialFetchData }));
      setEditId(null);
      ToastifyService.success("Updated Timesheet!");
    } catch (error) {
      console.error("Error saving data:", error);
      ToastifyService.error("Failed to update Timesheet!");
    }
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;

    setEditedRow((prevState) => {
      const updatedRow = { ...prevState, [field]: value };
      let invoiceAmount = updatedRow?.invoiceAmount;
      if (field === "transactions" && forumla === "transactions") {
        if (props?.data?.contractName === "Transaction Amount") {
          invoiceAmount = value * (2 || 1);
          setEditedata({ invoiceAmount: invoiceAmount });
        } else if (props?.data?.contractName === "FTE Amount") {
          const fteDeployed = value / (ContractTypes?.transactionTarget || 1);
          invoiceAmount = fteDeployed * (amount || 1);
          updatedRow.fteDeployed = fteDeployed;
          setEditedata({
            FTEDeployed: fteDeployed,
            invoiceAmount: invoiceAmount,
            FTEAmount: amount,
          });
        }
      } else if (
        field === "clientCollections" &&
        forumla === "clientCollections"
      ) {
        invoiceAmount = value;
          updatedRow.contingencyAmount = value;
          setEditedata({ invoiceAmount, ContingencyAmount: value });
      } else if (forumla === "flatefee") {
        invoiceAmount = value;
        updatedRow.flatFee = value;
        setEditedata({ invoiceAmount, flatFee: value });
      }
      updatedRow.invoiceAmount = invoiceAmount;
      return updatedRow;
    });
  };

  const today = new Date(); // Get today's date

  // Format the date as "MM, DD, YYYY"
  const formatDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString("en-US", options).replace(/\//g, "/ ");
  };

  // Set the dates
  const invoiceDate = formatDate(today);
  const paymentDueDate = formatDate(
    new Date(today.setMonth(today.getMonth() + 1))
  );
  const handleSubmit = () => {
    const data = {
      FinaceRemarks: remarks,
      Approver: 0,
      months: props?.data?.months,
      clientid: props?.data?.clientId,
      subscriptionid: props?.data?.subscriptionId,
    };

    const InvoiceData = { 
      id: "5ed68f5c-db32-4841-8aa1-99b1f8721100",
      createdBy: auth?.id,
      months: props?.data?.months,
      clientId: props?.data?.clientId,
      subscriptionId: props?.data?.subscriptionId,
      timeSheetId:props?.data?.id,
      status: "5ed68f5c-db32-4841-8aa1-99b1f8721100",
    };
    // Prompt the user for confirmation
    Swal.fire({
      title: "Do you want to generate an invoice?",
      text: "This will create a new invoice based on the timesheet data.",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, generate it!",
      cancelButtonText: "No, cancel",
    })
      .then((result) => {
        if (result?.isConfirmed) {
          // User confirmed: proceed with dispatch and template generation
          dispatch(AddFinanceTimeSheetStatus(data));
          setShowCreateTemplate(true);
          ToastifyService.info("Timesheet submission was UnBilled.");
          dispatch(AddInvoice(InvoiceData));
        } else if (result?.dismiss === Swal.DismissReason.cancel) {
          // User canceled: Do nothing or show a message
          setShowCreateTemplate(false);
          ToastifyService.info("Timesheet submission was canceled.");
        }
      });
      
  };

  const handleReject = () => {
    const data = {
      FinaceRemarks: remarks,
      Approver: 1,
      months: props?.data?.months,
      clientid: props?.data?.clientId,
      subscriptionid: props?.data?.subscriptionId,
    };
    dispatch(AddFinanceTimeSheetStatus(data));
    ToastifyService.success(
      "Operational Finance Rejected Timesheet Successfully Submitted!"
    );
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-90"
      >
        <Template4
          show={showCreateTemplate}
          closeFunction={() => setShowCreateTemplate(false)}
          data={props}
        />
        <>
          {!showCreateTemplate && (
            <>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title className="fs-16 text-dark">
                  Finance manager TimeSheet
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Table className="mb-0" responsive>
                  <thead>
                    <tr>
                      <th>Day</th>
                    

                      {props?.data?.contractName === "Transaction Amount" ||
                      props?.data?.contractName === "FTE Amount" ? (
                        <>
                          <th>Transactions</th>
                          <th>FTE Deployed</th>
                        </>
                      ) : null}

{props?.data?.contractName === "Contingency Amount" ||
                  props?.data?.contractName === "flatefee" ? (
                  <>
                <th>{props?.data?.contractName}</th>
                </>
              ) : null}
                      <th>Invoice Amount</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {DayWiseTimeSheetList.map((item, index) => {
                      const isWeekend =
                        item.daysWise === "Saturday" ||
                        item.daysWise === "Sunday";

                      return (
                        <tr
                          key={index}
                          style={
                            isWeekend ? { backgroundColor: "#d3d3d3" } : {}
                          }
                        >
                           <td>{item.daysWise}</td>
                                                    {props?.data?.contractName === "Transaction Amount" ||
                                                    props?.data?.contractName === "FTE Amount" ? (
                                                      <>
                                                        <td>
                                                          {item.id === editId && !isWeekend ? (
                                                            <Form.Control
                                                              type="number"
                                                              value={editedRow?.transactions || ""}
                                                              onChange={(e) =>
                                                                handleInputChange(e, "transactions")
                                                              }
                                                            />
                                                          ) : (
                                                            item.transactions
                                                          )}
                                                        </td>
                                                        <td>
                                                          <Form.Control
                                                            type="number"
                                                            value={
                                                              item.id === editId
                                                                ? editedRow?.fteDeployed
                                                                : item?.fteDeployed
                                                            }
                                                            disabled
                                                          />
                                                        </td>
                                                      </>
                                                    ) : null}
                          
                                                    {props?.data?.contractName === "Contingency Amount" ? (
                                                      <>
                                                        <td>
                                                          {item.id === editId ? (
                                                            <Form.Control
                                                              type="number"
                                                              value={editedRow?.contingencyAmount}
                                                              onChange={(e) =>
                                                                handleInputChange(e, "clientCollections")
                                                              }
                                                            />
                                                          ) : (
                                                            item.contingencyAmount
                                                          )}
                                                        </td>
                                                      </>
                                                    ) : null}
                                                    {props?.data?.contractName === "flatfee" ? (
                                                      <>
                                                        <td>
                                                          {item.id === editId ? (
                                                            <Form.Control
                                                              type="number"
                                                              value={editedRow?.flatFee}
                                                              onChange={(e) =>
                                                                handleInputChange(e, "flatfee")
                                                              }
                                                            />
                                                          ) : (
                                                            item.flatFee
                                                          )}
                                                        </td>
                                                      </>
                                                    ) : null}
                          
                          <td>
                            <Form.Control
                              type="number"
                              value={
                                item.id === editId
                                  ? editedRow?.invoiceAmount
                                  : item?.invoiceAmount
                              }
                              disabled
                            />
                          </td>
                          <td>
                            {item.id === editId ? (
                              <Button
                                variant="success"
                                onClick={handleSaveClick}
                                disabled={isWeekend}
                              >
                                Save
                              </Button>
                            ) : (
                              <Button
                                variant="primary"
                                className="btn-icon"
                                onClick={() => handleEditClick(item)}
                                disabled={isWeekend}
                              >
                                <i className="ri-pencil-fill"></i>
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                    <th className="bg-gray-200">Total</th>
                      {props?.data?.contractName === "Transaction Amount" ||
                      props?.data?.contractName === "FTE Amount" ? (
                        <>
                          <th className="bg-gray-200">
                            {DayWiseTimeSheetList.reduce(
                              (acc, item) => acc + (item?.transactions || 0),
                              0
                            )}
                          </th>
                          <th className="bg-gray-200">
                            {DayWiseTimeSheetList.reduce(
                              (acc, item) =>
                                acc + (Math.round(item?.fteDeployed) || 0),
                              0
                            )}
                          </th>
                        </>
                      ) : null}
 {props?.data?.contractName === "Contingency Amount" ? (
                            <>
                              <th>
                              {DayWiseTimeSheetList.reduce(
                              (acc, item) => acc + (item?.contingencyAmount || 0),
                              0
                            )}
                                 
                              </th>
                            </>
                          ) : null}
                          {props?.data?.contractName === "flatFee" ? (
                            <>
                              <th>
                              {DayWiseTimeSheetList.reduce(
                              (acc, item) => acc + (item?.flatFee || 0),
                              0
                            )}
                                 
                              </th>
                            </>
                          ) : null}
                      
                      {/* <th className="bg-gray-200">
                        {DayWiseTimeSheetList.reduce(
                          (acc, item) => acc + (Math.round(item?.invoiceAmount) || 0),
                          0
                        )}
                      </th> */}
                      <th className="bg-gray-200">{baseAmount}</th>

                    </tr>
                  </tfoot>
                </Table>
              </Offcanvas.Body>
              <div className="offcanvas-footer justify-content-between">
                <div className="row">
                  <label>
                    Operation Remarks :
                    <Form.Control
                      as="textarea"
                      className="wt-500"
                      placeholder="Operation Remark.."
                      value={props?.data?.operationsRemarks || ""}
                      disabled
                    />
                  </label>
                </div>
                <div className="row">
                  <label>
                    Finance Remarks :
                    <Form.Control
                      as="textarea"
                      className="wt-500"
                      placeholder="Finance Remark.."
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                    />
                  </label>
                </div>
                <div className="d-flex gap-2">
                  <Button
                    type="button"
                    className="text-uppercase"
                    variant="outline-danger"
                    onClick={handleReject}
                  >
                    Reject
                  </Button>
                  <Button
                    type="button"
                    className="text-uppercase"
                    variant="outline-primary"
                    onClick={handleSubmit}
                  >
                    Approve
                  </Button>
                </div>
              </div>
            </>
          )}
        </>
      </Offcanvas>
    </React.Fragment>
  );
}
