import React, { useState, useEffect } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { format, subDays, startOfMonth, endOfMonth, addDays, getDay } from "date-fns";
import ToastifyService from "../_common/ToastifyService";

export default function WeeksModal(props) {
    const [getWeeksData, setGetWeeksData] = useState(null);
    const [data, setData] = useState([]);
    const [editId, setEditId] = useState(null);
    const [editedRow, setEditedRow] = useState([]);
    const [thursdays, setThursdays] = useState([]);

    useEffect(() => {
        if (props.data) {
            setGetWeeksData(props.data);
            const filteredData = filterThursdays(props.data.month_data || []);
            setData(filteredData);
        }
    }, [props.data]);

    // Generate mock data (30 days by default)
    // const generateData = () => {
    //     const today = new Date();
    //     return Array.from({ length: 30 }).map((_, i) => {
    //         const date = subDays(today, i);
    //         return {
    //             id: i + 1,
    //             day: format(date, "yyyy-MM-dd"),
    //             transactions: Math.floor(Math.random() * 200),
    //             clientCollections: Math.floor(Math.random() * 5000),
    //             fteDeployed: Math.floor(Math.random() * 10),
    //             operationsRemarks: "On track",
    //             financeRemarks: "Clear",
    //             transactionAmount: Math.floor(Math.random() * 10000),
    //             fteAmount: Math.floor(Math.random() * 5000),
    //             contingencyAmount: Math.floor(Math.random() * 1000),
    //             invoiceAmount: Math.floor(Math.random() * 15000),
    //         };
    //     });
    // };

    // Calculate all Thursdays in a given month
    const getThursdaysInMonth = (year, month) => {
        const firstDayOfMonth = startOfMonth(new Date(year, month, 1));
        const lastDayOfMonth = endOfMonth(firstDayOfMonth);

        let thursdays = [];
        let day = firstDayOfMonth;

        // Find the first Thursday of the month
        while (getDay(day) !== 4) {
            day = addDays(day, 1);
        }

        // Add all Thursdays to the array
        while (day <= lastDayOfMonth) {
            thursdays.push(day);
            day = addDays(day, 7);
        }

        return thursdays;
    };

    const filterThursdays = (data) => {
        return data.filter(item => {
            const date = new Date(item.day);
            return getDay(date) === 4; // 4 corresponds to Thursday
        });
    };

    useEffect(() => {
        const year = new Date().getFullYear();
        const month = new Date().getMonth(); // Current month
        const calculatedThursdays = getThursdaysInMonth(year, month);
        setThursdays(calculatedThursdays);
    }, []);

    const handleEditClick = (row) => {
        setEditId(row.id);
        setEditedRow({ ...row });
    };

    const handleSaveClick = () => {
        setData(data.map((item) => (item.id === editId ? editedRow : item)));
        setEditId(null);
        ToastifyService.success("Updated Timesheet!");
    };

    const handleInputChange = (e, field) => {
        setEditedRow({ ...editedRow, [field]: e.target.value });
    };

    const columns = [
        {
            name: "Day",
            selector: (row) => row?.day,
        },
        {
            name: "Transactions",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.transactions}
                        onChange={(e) => handleInputChange(e, "transactions")}
                    />
                ) : (
                    row.transaction
                ),
        },
        {
            name: "Client Collections",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.client_collection}
                        onChange={(e) => handleInputChange(e, "client_collection")}
                    />
                ) : (
                    row.client_collection
                ),
        },
        {
            name: "FTE Deployed",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.FTE_deployed}
                        onChange={(e) => handleInputChange(e, "FTE_deployed")}
                    />
                ) : (
                    row.FTE_deployed
                ),
        },
        {
            name: "Operations Remarks",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="text"
                        value={editedRow.operation_remarks}
                        onChange={(e) => handleInputChange(e, "operation_remarks")}
                    />
                ) : (
                    row.operation_remarks
                ),
        },
        {
            name: "Finance Remarks",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="text"
                        value={editedRow.finance_remark}
                        onChange={(e) => handleInputChange(e, "finance_remark")}
                    />
                ) : (
                    row.finance_remark
                ),
        },
        {
            name: "Transaction Amount",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.transaction_amt}
                        onChange={(e) => handleInputChange(e, "transaction_amt")}
                    />
                ) : (
                    <>${row.transaction_amt}</>
                ),
        },
        {
            name: "FTE Amount",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.fte_amt}
                        onChange={(e) => handleInputChange(e, "fte_amt")}
                    />
                ) : (
                    <>${row.fte_amt}</>
                ),
        },
        {
            name: "Contingency Amount",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.contingency_amount}
                        onChange={(e) => handleInputChange(e, "contingency_amount")}
                    />
                ) : (
                    <>${row.contingency_amount}</>
                ),
        },
        {
            name: "Invoice Amount",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.invoice_amt}
                        onChange={(e) => handleInputChange(e, "invoice_amt")}
                    />
                ) : (
                    <>${row.invoice_amt}</>
                ),
        },
        {
            name: "Actions",
            cell: (row) =>
                row.id === editId ? (
                    <Button variant="success" onClick={handleSaveClick}>
                        Savewww
                    </Button>
                ) : (
                    <Button
                        variant="primary"
                        className="btn-icon"
                        onClick={() => handleEditClick(row)}
                    >
                        <i className="ri-pencil-line"></i>
                    </Button>
                ),
        },
    ];

    return (
        <React.Fragment>
            <Offcanvas
                show={props.show}
                onHide={props.closeFunction}
                placement="end"
                className="w-90"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">
                        {getWeeksData?.month}
                    </Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <h5>Thursday in this Month:</h5>
                    <DataTable
                        columns={columns}
                        data={filterThursdays(data)} // Use filtered data
                        fixedHeader
                        highlightOnHover
                        pagination
                        className="min-height-auto max-height-none"
                        paginationPerPage={31}
                    />
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    );
}
