import React, { forwardRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
//import userAvatar from "../assets/img/img1.jpg";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const Header = (onSkin) => {
  const axiosPrivate = useAxiosPrivate();
  const { setAuth, auth } = useAuth();
  const [pendingQueries, setPendingQueries] = useState([]);

  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));
 
  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  };

  const skinMode = (e) => {
    e.preventDefault();
    e.target.classList.add("active");

    let node = e.target.parentNode.firstChild;
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("active");
      node = node.nextElementSibling || node.nextSibling;
    }

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    if (skin === "dark") {
      HTMLTag.setAttribute("data-skin", skin);
      localStorage.setItem("skin-mode", skin);

      onSkin(skin);
    } else {
      HTMLTag.removeAttribute("data-skin");
      localStorage.removeItem("skin-mode");

      onSkin("");
    }
  };

  return (
    <div className="header-main px-3 px-lg-4">
      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4"><i className="ri-menu-2-fill"></i></Link>
      <div className="me-auto"></div>


      {/* SKIN MODE DARK AND LIGHT THEME */}
      {/* <Dropdown className="dropdown-skin ms-3" align="end">
          <Dropdown.Toggle as={CustomToggle}>
            <i className="ri-settings-3-line"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu className="mt-10-f">
            <label>Skin Mode</label>
            <nav className="nav nav-skin">
              <Link
                onClick={skinMode}
                className={
                  localStorage.getItem("skin-mode")
                    ? "nav-link"
                    : "nav-link active"
                }
              >
                Light
              </Link>
              <Link
                onClick={skinMode}
                className={
                  localStorage.getItem("skin-mode")
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                Dark
              </Link>
            </nav>
          
          </Dropdown.Menu>
        </Dropdown> */}

      
      {/* USER LOGOUT OPTION */}
      <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <div className="avatar online">
            <div className="user-profile">
              <i className="ri-user-fill"></i>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="mt-10-f">
          <div className="dropdown-menu-body">
            <div className="avatar avatar-xl online mb-3">
              <div className="user-profile">
              <i className="ri-user-fill fs-36"></i>
            </div>
              </div>
            <h5 className="mb-1 text-dark fw-semibold fs-14" style={{wordBreak: "break-word"}}>{auth?.name}</h5>
            <p className="fs-sm text-secondary fs-12">{auth?.roles[0]}</p>

            {/* <nav className="nav">
              <Link to=""><i className="ri-edit-2-line"></i> Edit Profile</Link>
              <Link to=""><i className="ri-profile-line"></i> View Profile</Link>
            </nav>
            */}
             <hr />
            <nav className="nav">
              {/* <Link to=""><i className="ri-question-line"></i> Help Center</Link>
              <Link to=""><i className="ri-lock-line"></i> Privacy Settings</Link>
              <Link to=""><i className="ri-user-settings-line"></i> Account Settings</Link> */}
              <Link to="/account/login" onClick={() => {
                sessionStorage.clear();
                setAuth({});
              }
              }><i className="ri-logout-box-r-line"></i> Log Out</Link>
            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
};

export default Header;