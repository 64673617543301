import React, { useState, useEffect, useRef } from "react";
import Header from "../../../layouts/Header";
import { Button, Form, Row, Col, Card, Badge } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { format, subDays } from "date-fns";
import { Link, useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";

export default function ManagementMonthView() {
    const [data, setData] = useState([]);
    const [editId, setEditId] = useState(null);
    const [editedRow, setEditedRow] = useState({});
    const location = useLocation();
    const rowData = location.state.row || {};
    const [gettimeSheetData, setGettimeSheetData] = useState(rowData);

    useEffect(() => {
        console.log("gettimeSheetData", gettimeSheetData);
        if (gettimeSheetData) {
            setData(gettimeSheetData?.months || "")
            // const data = {
            //   Id: rowData?.clientId,
            //   SubsId: rowData?.subscriptionId,
            //   monthId: rowData?.months,
            // };
        }
    }, [gettimeSheetData.month_data]);

    // Generate 30 days of mock data (if needed)
    const generateData = () => {
        const today = new Date();
        return Array.from({ length: 30 }).map((_, i) => {
            const date = subDays(today, i);
            return {
                id: i + 1,
                day: format(date, "yyyy-MM-dd"),
                transactions: Math.floor(Math.random() * 200),
                clientCollections: Math.floor(Math.random() * 5000),
                fteDeployed: Math.floor(Math.random() * 10),
                operationsRemarks: "On track",
                financeRemarks: "Clear",
                transactionAmount: Math.floor(Math.random() * 10000),
                fteAmount: Math.floor(Math.random() * 5000),
                contingencyAmount: Math.floor(Math.random() * 1000),
                invoiceAmount: Math.floor(Math.random() * 15000),
            };
        });
    };

    useEffect(() => {
        if (data.length === 0) {
            setData(generateData()); // Generate mock data if no data is present
        }
    }, [data]);

    const handleEditClick = (row) => {
        setEditId(row.id);
        setEditedRow({ ...row });
    };

    const handleSaveClick = () => {
        setData(data.map((item) => (item.id === editId ? editedRow : item)));
        setEditId(null);
    };

    const handleInputChange = (e, field) => {
        setEditedRow({ ...editedRow, [field]: e.target.value });
    };

    const columns = [
        {
            name: "Day",
            selector: (row) => row?.day,
        },
        {
            name: "Transactions",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.transaction}
                        onChange={(e) => handleInputChange(e, 'transaction')}
                    />
                ) : (
                    row.transaction
                ),
        },
        {
            name: "Client Collections",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.client_collection}
                        onChange={(e) => handleInputChange(e, 'client_collection')}
                    />
                ) : (
                    row.client_collection
                ),
        },
        {
            name: "FTE Deployed",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.FTE_deployed}
                        onChange={(e) => handleInputChange(e, 'FTE_deployed')}
                    />
                ) : (
                    row.FTE_deployed
                ),
        },
        {
            name: "Operations Remarks",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="text"
                        value={editedRow.operation_remarks}
                        onChange={(e) => handleInputChange(e, 'operation_remarks')}
                    />
                ) : (
                    row.operation_remarks
                ),
        },
        {
            name: "Finance Remarks",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="text"
                        value={editedRow.finance_remark}
                        onChange={(e) => handleInputChange(e, 'finance_remark')}
                    />
                ) : (
                    row.finance_remark
                ),
        },
        {
            name: "Transaction Amount",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.transaction_amt}
                        onChange={(e) => handleInputChange(e, 'transaction_amt')}
                    />
                ) : (
                    <>${row.transaction_amt}</>
                ),
        },
        {
            name: "FTE Amount",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.fte_amt}
                        onChange={(e) => handleInputChange(e, 'fte_amt')}
                    />
                ) : (
                    <>${row.fte_amt}</>
                ),
        },
        {
            name: "Contingency Amount",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.contingency_amount}
                        onChange={(e) => handleInputChange(e, 'contingency_amount')}
                    />
                ) : (
                    <>${row.contingency_amount}</>
                ),
        },
        {
            name: "Invoice Amount",
            cell: (row) =>
                row.id === editId ? (
                    <Form.Control
                        type="number"
                        value={editedRow.invoice_amt}
                        onChange={(e) => handleInputChange(e, 'invoice_amt')}
                    />
                ) : (
                    <>${row.invoice_amt}</>
                ),
        },
        {
            name: "Actions",
            cell: (row) =>
                row.id === editId ? (
                    <Button variant="success" onClick={handleSaveClick}>Save</Button>
                ) : (
                    <Button variant="primary" className="btn-icon" onClick={() => handleEditClick(row)}><i className="ri-pencil-line"></i></Button>
                ),
        },
    ];

     // PROGRESS TOP LOADER
     const loadingBarRef = useRef(null);
     useEffect(() => {
         if (loadingBarRef.current) {
             // Start the loading bar
             loadingBarRef.current.continuousStart();
 
             // Simulate a load completion or manage dynamically
             const timer = setTimeout(() => {
                 loadingBarRef.current.complete();
             }, 3000); // Adjust timing as needed
 
             // Cleanup timer
             return () => clearTimeout(timer);
         }
     }, []);
 

    return (
        <React.Fragment>
            <Header />
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="main main-app manage-item-page p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item">
                                <Link to="/management/manage">Management</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">{gettimeSheetData?.month_year} View</li>
                        </ol>
                        <h4 className="main-title mb-0">{`${gettimeSheetData?.month_year}`}</h4>
                    </div>
                </div>
                <Row>
                    <Col xl={12}>
                        <Card className="card-one h-auto mb-3">
                            <Card.Body>
                                <div className="d-flex flex-wrap justify-content-between gap-5">
                                <div>
                                        <h6 className="fs-14 fw-bold mb-1">{gettimeSheetData?.month_year}</h6>
                                        <Form.Label>Month / Year</Form.Label>
                                    </div>
                                    <div>
                                        <h6 className="fs-14 fw-bold mb-1">{gettimeSheetData?.finance_remarks}</h6>
                                        <Form.Label>Operations Remarks</Form.Label>
                                    </div>
                                    <div>
                                        <h6 className="fs-14 fw-bold mb-1">{gettimeSheetData?.transaction_amount}</h6>
                                        <Form.Label>Finance Remarks</Form.Label>
                                    </div>
                                    <div>
                                        <div>
                                            <Badge bg="primary" className="mb-1" pill>Ongoing</Badge>
                                        </div>
                                        <Form.Label>Timesheet Status</Form.Label>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>

                        <Card className="card-one timesheet-table-card-height">
                            <Card.Body className="overflow-y custom-scroll">
                                <DataTable
                                    columns={columns}
                                    data={gettimeSheetData?.month_data}
                                    fixedHeader
                                    highlightOnHover
                                    pagination
                                    className="min-height-auto max-height-none"
                                    paginationPerPage={31}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}
