import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import clientMasterData from "../../../Json/Setting/client-master.json";
import { Badge, Button, Card, Form } from "react-bootstrap";
import SweetAlertService from "../../_common/SweetAlertService";
import ToastifyService from "../../_common/ToastifyService";
import CreateClientMaster from "./create-client-master";
import { useDispatch, useSelector } from "react-redux";
import { getAllClientmasterList } from "../../../application/selectors/indexSelector";
import { GetClientMasterList } from "../../../application/actions/clientAction";
import LoadingBar from 'react-top-loading-bar';

export default function ClientMaster() {
    const dispatch = useDispatch();
    const ClietMasterList = useSelector(getAllClientmasterList);
    useEffect(() => {
        dispatch(GetClientMasterList);
    }, []);

    const columns = [
        {
            name: "Client Code",
            selector: (row) => row.clientCode,
            sortable: true,
        },
        {
            name: "Client Name",
            selector: (row) => row.clienName,
            sortable: true,
        },
        {
            name: "Cost Center",
            selector: (row) => row.clientCenter,
            sortable: true,
        },

        {
            name: "Status",
            selector: (row) => (
                <Badge
                    bg={row.fieldValue === "Active" ? "success" : "danger"}
                    pill
                >
                    {row.fieldValue}
                </Badge>
            ),
            sortable: true,
        },
        {
            name: "Created By",
            selector: (row) => row.fullName,
            sortable: true,
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex justify-content-end">
                    <Button variant="primary" className="btn-icon me-2" onClick={() => handleUpdateClientMaster(row)}>
                        <i className="ri-pencil-line"></i>
                    </Button>
                    <Button variant="outline-danger" className="btn-icon" onClick={() => handleDelete(row)}>
                        <i className="ri-delete-bin-line"></i>
                    </Button>
                </div>
            ),
            width: "200px"
        },
    ];

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredEmployees = ClietMasterList?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // HANDLE DELETE FUNCTION
    const [data, setData] = useState([]);
    const handleDelete = (rowData, index) => {
        SweetAlertService.showAlert(
            rowData.client_name,
            "Are you sure you want to delete this Client Name?",
            "warning"
        )
            .then((result) => {
                if (result.isConfirmed) {
                    ToastifyService.success(`Client Name Deleted Successfully`);
                    const newData = [...data];
                    newData.splice(index, 1);
                    setData(newData);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                ToastifyService.error(`Something went wrong`);
            });
    };

    const [showClientMaster, setShowClientMaster] = useState(false);
    const [selectedField, setSelectedField] = useState(null)
    const handleCreateClientMaster = (state) => {
        setShowClientMaster(state);
    };
    const handleUpdateClientMaster = (data) => {
        setShowClientMaster(true);
        setSelectedField(data)
        console.log(data)
    };


    // PROGRESS TOP LOADER
    const loadingBarRef = useRef(null);
    useEffect(() => {
        if (loadingBarRef.current) {
            // Start the loading bar
            loadingBarRef.current.continuousStart();

            // Simulate a load completion or manage dynamically
            const timer = setTimeout(() => {
                loadingBarRef.current.complete();
            }, 3000); // Adjust timing as needed

            // Cleanup timer
            return () => clearTimeout(timer);
        }
    }, []);

    return (
        <React.Fragment>
            <CreateClientMaster show={showClientMaster}
                closeFunction={handleCreateClientMaster}
                data={selectedField} />
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="fs-14">Client Master</h4>
                </div>

                <div className="d-flex gap-2 mt-3 mt-md-0">
                    <Button variant="primary" className="d-flex align-items-center gap-2" onClick={() => handleCreateClientMaster(true)}>
                        <i className="ri-add-line fs-18 lh-1"></i><span className="d-sm-inline">Create Client Master</span>
                    </Button>
                </div>
            </div>

            <Card className="card-one">
                <Card.Header className="align-items-center justify-content-between">
                    <h4 className="main-title fs-14 mb-0">List of Client Master</h4>
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-300">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <DataTable
                        columns={columns}
                        data={filteredEmployees}
                        fixedHeader
                        search={true}
                        highlightOnHover
                        pagination
                    ></DataTable>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}