import React, { useEffect, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCommonListData } from "../../../application/selectors/indexSelector";
import { GetCommonList } from "../../../application/actions/commonAction";
import { AddMasterSub } from "../../../application/actions/subscriptionAction";

export default function CreateSubscriptionMaster(props) {
  console.log(props?.data,"djds")
  const dispatch = useDispatch();
  const [SubName, setSubName] = useState("");
  const [StatusId, setStatus] = useState("");
  let statuss = [];
  const Comomlist = useSelector(getCommonListData);
  localStorage.setItem("ContractTypelist", JSON.stringify(Comomlist));

  // Retrieve the data from localStorage and parse it
  const storedData = JSON.parse(localStorage.getItem("ContractTypelist"));
  if (storedData) {
    // Filter data based on fieldTypeCode being "STS"
    statuss = storedData.filter((item) => item.fieldTypeCode === "STS");
  }

  useEffect(() => {
    dispatch(GetCommonList);
  }, [dispatch]);

  useEffect(() => {
    if (props?.data) {
      setSubName(props?.data?.subsName)
      setStatus(props?.data?.status)
    }
  
  }, [props?.data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa6", 
      status: StatusId,
      createdBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6", 
      createdOn: new Date().toISOString(),
      modifiedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6", 
      modifiedOn: new Date().toISOString(),
      subsName: SubName,
    };

    console.log("data", data);
    try {
     dispatch(AddMasterSub(data));
      props.closeFunction();
      setSubName("");
      setStatus("");
    } catch (error) {
      console.error("Error creating subscription master:", error);
    }
  };

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Add New Subscription Master
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Form >
          
            {/* Attach handleSubmit to the form */}
            <Row>
              <Col md={8}>
                <div className="mb-4">
                  <Form.Label>Subscription Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Subscription Name"
                    autoComplete="off"
                    value={SubName}
                    onChange={(e) => setSubName(e.target.value)} // Update state on change
                    required // Optional: Make this field required
                  />
                </div>

                <div>
                  <Form.Label>Status</Form.Label>
                  <div className="subscription-status-switch">
                    <Form.Select
                      value={StatusId}
                      onChange={(e) => setStatus(e.target.value)} // Update state on change
                      required
                    >
                      <option value="">Choose Status</option>
                      {statuss.map((item) => (
                        <option key={item.id} value={StatusId}>
                          {item.fieldValue}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          {" "}
       
          <Button
            type="submit"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
           onClick={handleSubmit} 
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Create Subscription Master</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
