import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Button, Card, Col, Form, ListGroup, Offcanvas, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GetCommonList, GetContractTypeList } from "../../../application/actions/commonAction";
import { getCommonListData, getContractListData, getMasterSubscrtiptionData, getUsersRolelist } from "../../../application/selectors/indexSelector";
import { GetUsersListById } from "../../../application/actions/usersAction";
import useAuth from "../../../hooks/useAuth";
import { Add_Subs, GetMasterSub } from "../../../application/actions/subscriptionAction";


export default function CreateSubscription(props) {
  console.log(props?.data,"ffalleld")
  const { setAuth, auth } = useAuth();
  const dispatch = useDispatch();
  let contracttype = [];
  let BilableStatus = [];
  const Comomlist = useSelector(getCommonListData);
  const [MasterSubs, setMasterSubs] = useState("");
  const [startDate, setStartDate] = useState("");
  const [termDate, setTermDate] = useState("");
  const [billableStatus, setBillableStatus] = useState("");
  const [Monthlymin, setMonthlymin] = useState(0);
  const [FTEApproved, setFTEApproved] = useState(0);
  const [TransactionTarget, setTransactionTarget] = useState(0);
  const [FTERate, setFTERate] = useState(0);
  const [TransactionCount, setTransactionCount] = useState(0);
  const [TransactionRate, setTransactionRate] = useState(0);
  const [Percentage, setPercentage] = useState(0);
  const [FeeAmount, setFeeAmount] = useState(0);
  const [reviwerList, setReviwerList] = useState([]);
  const [approverList, setApproverList] = useState([]);
  const [financeList, setFinanceList] = useState([]);
  const ContractTypelist = useSelector(getContractListData);
  const MasterList = useSelector(getMasterSubscrtiptionData);
  const ReviewerList = useSelector((state) => state.users.usersById2);
  const ApproverList = useSelector((state) => state.users.usersById6);
  const FinanceList = useSelector((state) => state.users.usersById3);

  // Use the data as needed
  localStorage.setItem("ContractTypelist", JSON.stringify(Comomlist));

  // Retrieve the data from localStorage and parse it
  const storedData = JSON.parse(localStorage.getItem("ContractTypelist"));

  if (storedData) {
    // Filter data based on fieldTypeCode being "CTT"
    contracttype = ContractTypelist;
    BilableStatus = storedData.filter(item => item.fieldTypeCode === "BS");
  }

  useEffect(() => {
    if (props.show) {
      dispatch(GetCommonList);
      dispatch(GetContractTypeList);
      dispatch(GetMasterSub);
      dispatch(GetUsersListById(2));
      dispatch(GetUsersListById(6));
      dispatch(GetUsersListById(3));

    }
  }, [props.show]);

  const [contractType, setContractType] = useState("0");

  const handleContractTypeChange = (e) => {
    const selectedType = e.target.value;
    setContractType(selectedType);
  };

  // console.log("ReviewerList",ReviewerList);
  // console.log("ApproverList",ApproverList);
  // console.log("FinanceList",FinanceList);



  const selectOpsReviewers = ReviewerList?.map((sub) => ({
    value: sub?.id,
    label: sub?.fullName,
  }));
  const selectOpsApprover = ApproverList?.map((sub) => ({
    value: sub?.id,
    label: sub?.fullName,
  }));
  const selectOpsFinanace = FinanceList?.map((sub) => ({
    value: sub?.id,
    label: sub?.fullName,
  }));

  // UPLOAD PDF FILE MULTIPLE SELECTABLE 
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null); // Ref to access the hidden file input

  // Handle file selection
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]); // Append new files
  };

  // Handle file removal
  const handleRemoveFile = (fileToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  };

  // Simulate file input click
  const handleUploadClick = () => {
    fileInputRef.current.click(); // Trigger the hidden file input
  };

  const handlestartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleTermDateChange = (e) => {
    setTermDate(e.target.value);
  };
  const handelBillableStatus = (e) => {
    setBillableStatus(e.target.value);
  };

  const handleMonthlyminChange = (e) => {
    setMonthlymin(e.target.value);
  };
  const handelFTEApproved = (e) => {
    setFTEApproved(e.target.value);
  };
  const handelTransactionTarget = (e) => {
    setTransactionTarget(e.target.value);
  };
  const handelFTERate = (e) => {
    setFTERate(e.target.value);
  };
  const handelTransactionCount = (e) => {
    setTransactionCount(e.target.value);
  };
  const handelTransactionRate = (e) => {
    setTransactionRate(e.target.value);
  };

  const handelPercentage = (e) => {
    setPercentage(e.target.value);
  };

  const handelFeeAmount = (e) => {
    setFeeAmount(e.target.value);
  };

  const handelReviewerList = (selectedOptions) => {
    console.log("Selected Reviewer:", selectedOptions);
    setReviwerList(selectedOptions || []);
  };
  const handelApproverList = (selectedOptions) => {
    console.log("Selected Approver:", selectedOptions);
    setApproverList(selectedOptions || []);
  };
  const handelFinanaceList = (selectedOptions) => {
    console.log("Selected Finance:", selectedOptions);
    setFinanceList(selectedOptions || []);
  };
  const handleSubscriptionChange = (event) => {
    const selectedId = event?.target?.value;
    setMasterSubs(selectedId);
  }

    const [rows, setRows] = useState([{ from: "0", to: "0", percentage: "0" }]);
  
    const handleAddRow = () => {
      setRows([...rows, { from: "", to: "", percentage: "" }]);
    };
  
    const handleInputChange = (index, field, value) => {
      const updatedRows = [...rows];
      updatedRows[index][field] = value;
      setRows(updatedRows);
    };
  
    const handleRemoveRow = (index) => {
      const updatedRows = rows.filter((_, i) => i !== index);
      setRows(updatedRows);
    };
    console.log("setRows",rows);
  

  const handleSubmit = () => {
    // Data preparation
    const data = {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      status: "5ed68f5c-db32-4841-8aa1-99b1f8721100",
      createdBy: auth?.id,
      createdOn: new Date().toISOString(),
      modifiedBy: auth?.id,
      modifiedOn: new Date().toISOString(),
      mSubscriptionId: MasterSubs,
      contractTypeId: contractType,
      billableStatus: billableStatus,
      reviewerId: reviwerList?.map((item) => item.value) || [],
      approverId: approverList?.map((item) => item.value) || [],
      managerId: financeList?.map((item) => item.value) || [],
      startDate,
      termDate,
      monthlyMinimum: Monthlymin,
      fteApproved: FTEApproved,
      transactionTarget: TransactionTarget,
      fteRate: FTERate,
      contingencyPercentage: Percentage,
      transactionCount: TransactionCount,
      transactionRate: TransactionRate,
      flatFee: FeeAmount,
      contingencies:rows
    };

    console.log("Submitting data:", data);

    // Dispatch action
    try {
      dispatch(Add_Subs(data));

      // Reset fields
      setMasterSubs("");
      setStartDate("");
      setTermDate("");
      setBillableStatus("");
      setMonthlymin(0);
      setFTEApproved(0);
      setTransactionTarget(0);
      setFTERate(0);
      setTransactionCount(0);
      setTransactionRate(0);
      setPercentage(0);
      setFeeAmount(0);
      setReviwerList([]);
      setApproverList([]);
      setFinanceList([]);

      // Close modal or execute any other cleanup
      if (props.closeFunction) {
        props.closeFunction();
      }
    } catch (error) {
      console.error("Failed to add subscription:", error);
    }
  };

  useEffect(() => {
    if(props?.data){
      setMasterSubs(props?.data?.subsName);
      setStartDate(props?.data?.contractName);
      setTermDate();
      setBillableStatus(props?.data?.billableStatus);
      setMonthlymin(0);
      setFTEApproved(0);
      setTransactionTarget(0);
      setFTERate(0);
      setTransactionCount(0);
      setTransactionRate(0);
      setPercentage(0);
      setFeeAmount(0);
      setReviwerList([]);
      setApproverList([]);
      setFinanceList([]);
    }
   
  }, [props?.data]);

  return (
    <React.Fragment>
      <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-50">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">{props?.data?"Update":"Add New "} Subscription</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col md={8}>
              <div className="mb-4">
                <Form.Label>Name</Form.Label>
                <Form.Select onChange={handleSubscriptionChange}>
                  <option>Choose Subscription </option>
                  {MasterList.map(item => (
                    <option key={item.id} value={item.id}>{item.subsName}</option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>
          <Row>

            <Col md={8}>
              <div className="mb-4">
                <Form.Label>Start Date</Form.Label>
                <Form.Control type="date" value={startDate}
                  onChange={handlestartDateChange} />
              </div>
            </Col>

            <Col md={8}>
              <div className="mb-4">
                <Form.Label>Term Date</Form.Label>
                <Form.Control type="date" value={termDate}
                  onChange={handleTermDateChange} />
              </div>
            </Col>

            <Col md={8}>
              <div className="mb-4">
                <Form.Label>Billing</Form.Label>
                <Form.Select onChange={handelBillableStatus}>
                  <option>Choose Status</option>
                  {BilableStatus.map(item => (
                    <option key={item.id} value={billableStatus}>{item.fieldValue}</option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <div className="mb-4">
                <Form.Label>Monthly Minimum</Form.Label>
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">$</span>
                  <Form.Control type="number" value={Monthlymin}
                    onChange={handleMonthlyminChange} />
                </div>
              </div>
            </Col>
          </Row>

          {/* SUBSCRIPTION */}
          <div className="custom-divider pt-4 mb-4"><span className="divider-title">Contract Type</span>
            <Row>
              <Col md={8}>
                <div className="mb-4">
                  <Form.Label>Contract Type</Form.Label>
                  <Form.Select
                    value={contractType}
                    onChange={handleContractTypeChange} // Pass selected value
                  >
                    <option value="">Choose Contract Type</option>
                    {contracttype.map(item => (
                      <option key={item.id} value={item.id}>{item.contractName}</option>
                    ))}
                  </Form.Select>
                </div>
              </Col>
            </Row>
            {/* SUBSCRIPTION */}
            {contractType > "" ? (
              <>
                <Row>
                  <Col md={12}>
                    <div>

                      {contractType === "6fd68c4c-c334-4395-8ec5-7545fe858482" && (
                        <>
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Form.Label>FTE Approved</Form.Label>
                                <Form.Control type="text" placeholder="Enter FTE Approved" value={FTEApproved} onChange={handelFTEApproved} />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Form.Label>Transaction Target</Form.Label>
                                <div className="input-group">
                                  <span className="input-group-text">$</span>
                                  <Form.Control className="form-control" placeholder="Enter Transaction Target" type="number" value={TransactionTarget} onChange={handelTransactionTarget} />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div>
                                <Form.Label>FTE Rate Per Day</Form.Label>
                                <div className="input-group">
                                  <span className="input-group-text">$</span>
                                  <Form.Control className="form-control" placeholder="Enter FTE Rate" type="number" value={FTERate} onChange={handelFTERate} />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>

                      )}

                      {contractType === "cb3fc135-0c8e-451b-a866-e82d6a2d42e2" && (
                        <>
                          <Row>
                            <Col md={6}>
                              <div>
                                <Form.Label>Transaction Count</Form.Label>
                                <div className="input-group">
                                  <span className="input-group-text">$</span>
                                  <Form.Control className="form-control" placeholder="Enter Transaction Count" type="number" value={TransactionCount} onChange={handelTransactionCount} />
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div>
                                <Form.Label>Transaction Rate</Form.Label>
                                <div className="input-group">
                                  <span className="input-group-text">$</span>
                                  <Form.Control className="form-control" placeholder="Enter Transaction Rate" type="number" value={TransactionRate} onChange={handelTransactionRate} />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}

{contractType === "e36e3ef5-5431-40df-9a1c-73e5573de8a9" && (
        <>
          {rows.map((row, index) => (
            <Row key={index} className="align-items-center mb-3">
              <Col md={4}>
                <Form.Label>From</Form.Label>
                <div className="input-group">
                  <span className="input-group-text">$</span>
                  <Form.Control
                    className="form-control"
                    placeholder="Enter From"
                    type="number"
                    value={row.from}
                    onChange={(e) => handleInputChange(index, "from", e.target.value)}
                  />
                </div>
              </Col>
              <Col md={4}>
                <Form.Label>To</Form.Label>
                <div className="input-group">
                  <span className="input-group-text">$</span>
                  <Form.Control
                    className="form-control"
                    placeholder="Enter To"
                    type="number"
                    value={row.to}
                    onChange={(e) => handleInputChange(index, "to", e.target.value)}
                  />
                </div>
              </Col>
              <Col md={2}>
                <Form.Label>Percentage</Form.Label>
                <div className="input-group">
                  <Form.Control
                    className="form-control"
                    placeholder="Enter Percentage"
                    type="number"
                    value={row.percentage}
                    onChange={(e) => handleInputChange(index, "percentage", e.target.value)}
                  />
                  <span className="input-group-text">%</span>
                </div>
              </Col>
              <Col md={1}>
                <Button variant="danger" className="btn-icon mt-4" onClick={() => handleRemoveRow(index)}>
                  <i className="ri-delete-bin-line"></i>
                </Button>
              </Col>
            </Row>
          ))}
          <Button variant="dark" className="d-flex align-items-center gap-1" onClick={handleAddRow}>
            <i className="ri-add-line"></i> 
          <span>Add Row</span>
          </Button>
        </>
      )}

                      {contractType === "b394a26c-bfeb-4ddd-b36b-ee7997816ce0" && (
                        <Row>
                          <Col md={6}>
                            <div>
                              <Form.Label>Fee Amount</Form.Label>
                              <div className="input-group">
                                <span className="input-group-text">$</span>
                                <Form.Control className="form-control" placeholder="Enter Fee Amount" type="number" value={FeeAmount} onChange={handelFeeAmount} />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </Col>
                </Row>
              </>

            ) : (
              <></>
            )}
          </div>

          {/* UPLOAD PDF FILE MULTIPLE SELECTABLE */}
          <div className="file-upload">
            <Form.Group controlId="formFileMultiple" className="mb-4">
              <Form.Control
                type="file"
                ref={fileInputRef} // Attach ref to input
                style={{ display: "none" }} // Hide the default input
                multiple
                accept="application/pdf"
                onChange={handleFileChange}
              />
            </Form.Group>

            {/* Custom button to trigger file upload */}
            <Button variant="primary" onClick={handleUploadClick}>
              <i className="ri-upload-line align-middle"></i> Select PDF Files
            </Button>
            {/* <Button variant="primary" onClick={handleUpload}>Upload Files</Button> */}
            {files.length > 0 && (
              <div className="mt-4">
                <h5 className="fs-14 fw-semibold mb-2">Selected Files:</h5>
                <Card className="card-one">
                  <Card.Body className="pb-0">
                    <ListGroup as="ul" className="list-group-one">
                      {files.map((file, index) => (
                        <ListGroup.Item
                          key={index}
                          as="li"
                          className="px-0 d-flex align-items-center justify-content-between gap-2"
                        >
                          <div>
                            <i className="ri-file-pdf-line fs-22 align-middle"></i>{" "}
                            {file.name}
                          </div>
                          <div>
                            <Button
                              variant="danger"
                              className="btn-icon"
                              onClick={() => handleRemoveFile(file)}
                            >
                              <i className="ri-close-line"></i>
                            </Button>
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Card.Body>
                </Card>
              </div>
            )}
          </div>

          {/* OPERATIONS REVIEWER */}
          <Row>
            <Col md={8}>
              <div className="mt-4">
                <Form.Label>Operations Reviewer</Form.Label>
                <Select options={selectOpsReviewers}
                  isSearchable={true}
                  isMulti
                  onChange={handelReviewerList}
                />
              </div>
            </Col>
          </Row>

          {/* OPERATIONS APPROVER */}
          <Row>
            <Col md={8}>
              <div className="mt-4">
                <Form.Label>Operations Approver</Form.Label>
                <Select options={selectOpsApprover} isSearchable={true} isMulti
                  onChange={handelApproverList}
                />
              </div>
            </Col>
          </Row>

          {/* FINANCE MANAGER */}
          <Row>
            <Col md={8}>
              <div className="mt-4">
                <Form.Label>Finanace Manager</Form.Label>
                <Select options={selectOpsFinanace} isSearchable={true} isMulti
                  onChange={handelFinanaceList} />
              </div>
            </Col>
          </Row>

        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            type="submit"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            onClick={handleSubmit}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">{props?.data?"Update":"Create"} Subscription</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
