import React, { useEffect, useState, useRef } from "react";
import Header from "../../../../layouts/Header";
import { Badge, Button, Card, Col, Form, Row } from "react-bootstrap";
import MonthsviewModal from "./months/months-wise";
import { GetClientDetailsBYId, GetFinanceTimeSheetDetails, GetTimeSheetFinanceCount } from "../../../../application/actions/financeTimeSheetAction";
import { useDispatch, useSelector } from "react-redux";
import { getClientListBYId, getClientSubscrtiption, getFinanceTimeDetailsList, getFinanceTimeSheetCount, getMonthListById } from "../../../../application/selectors/indexSelector";
import { GetClientListById } from "../../../../application/actions/clientAction";
import { GetClientSubscription } from "../../../../application/actions/subscriptionAction";
import { GetMonths } from "../../../../application/actions/timeSheetAction";
import DataTable from "react-data-table-component";
import useAuth from "../../../../hooks/useAuth";
import LoadingBar from "react-top-loading-bar";
import ShimmerLoader from "../../../_common/ShimmerLoader";

export default function FinanceManagerTimesheet() {
  const { setAuth, auth } = useAuth();
  const dispatch = useDispatch();
  const CountList = useSelector(getFinanceTimeSheetCount);
  const clientList = useSelector(getClientListBYId);
  const SubsclientList = useSelector(getClientSubscrtiption);
  const [clientId, setClientId] = useState(null);
  const [subsId, setSubsId] = useState(null);
  const [monthId, setMonthsId] = useState(null);
  const MonthWiseList = useSelector(getMonthListById);
  const FinanceTimeSheetList = useSelector(getFinanceTimeDetailsList) || [];
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "gettimesheet",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading({ isLoading: true, value: "gettimesheet" });
  
        // Dispatch actions sequentially or in parallel
        await Promise.all([
          dispatch(GetClientListById(auth?.id)),
          dispatch(GetTimeSheetFinanceCount(auth?.id)),
          dispatch(GetFinanceTimeSheetDetails(auth?.id))
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading({ isLoading: false, value: "" });
      }
    };
  
    fetchData();
  }, [auth?.id, dispatch, setLoading]);
  
  const TransactionsCount = CountList[0]?.totalTransactionsCount ?? 0;

  const ClientCollectionsCount = CountList[0]?.totalClientCollectionsCount ?? 0;
  const FTEDeployedCount = CountList[0]?.totalFTEDeployedCount ?? 0;

  const cardData = [
    {
      label: "Transactions",
      icon: "ri-functions",
      value: TransactionsCount,
      percent: "100",
      status: "up",
    },
    {
      label: "Client Collections",
      icon: "ri-shield-check-line",
      value: ClientCollectionsCount,
      status: "up",
    },
    {
      label: "FTE Deployed",
      icon: "ri-time-line",
      value: FTEDeployedCount,
      status: "up",
    },
  ];

  const handleClientChange = (event) => {
    const selectedClientId = event?.target?.value;
    setClientId(selectedClientId);
    dispatch(GetClientSubscription(selectedClientId));
  };
  const handleMonthsChange = (event) => {
    const MonthID = event.target.value;
    setMonthsId(MonthID);
  };

  console.log("FinanceTimeSheetList",FinanceTimeSheetList)

  const handleSubsChange = async (event) => {
    const data = {
      Id: clientId,
      SubsId: event?.target?.value,
    };
    setSubsId(event?.target?.value);
    try {
      dispatch(GetMonths(data)); // Assuming GetMonths is the Redux action
    } catch (error) {
      console.error("Error fetching months:", error);
    }
  };

  const columns = [
    {
      name: "Client/Subscription",
      selector: (row) => (
        <div>
          <h5 className="fs-14 mb-1">{row?.clientName}</h5>
        </div>
      ),
    },
    {
      name: "Month / Year",
      selector: (row) => row?.months,
    },
    {
      name: "TimeSheet Status",
      selector: (row) => (
        <Badge bg={row?.fieldValue === "Pending" ? "warning" : "success"} pill>
          {row?.fieldValue}
        </Badge>
      ),
    },
    {
      name: "Transactions",
      selector: (row) => row?.transactions,
    },
    {
      name: "Client Collections",
      selector: (row) => row?.contingencyAmount,
    },
    {
      name: "FTE Deployed",
      selector: (row) => row?.fteDeployed,
    },
    {
      name: "Transaction Amount",
      selector: (row) => row?.transactionAmount,
    },
    {
      name: "Contingency amount",
      selector: (row) => row?.contingencyAmount,
    },
    {
      name: "FTE Amount",
      selector: (row) => row?.fteAmount,
    },


    {
      name: "Invoice Amount",
      selector: (row) => row?.invoiceAmount,
    },

    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex align-items-center gap-2">
          <Button
            variant="outline-dark"
            className="btn-icon"
            onClick={() => handleMonthView(row)}
          >
            <i className="ri-eye-line"></i>
          </Button>
        </div>
      ),
    },
  ];

  const HandleSubmit = () => {
    const data = {
      Id: clientId, // Ensure clientId is set
      SubsId: subsId, // Ensure subsId is set
      monthId: monthId, // Ensure monthId is set
    };
    // Check if all required fields are filled
    if (clientId && subsId && monthId) {
      dispatch(GetClientDetailsBYId(data)); // Dispatch to get the filtered data
    } else {
      console.error("Please select all the required filters.");
      // Optionally show an alert or validation error to the user
    }
  };

  const [monthViewData, setMonthViewtData] = useState(null);
  const [showMonthsview, setShowMonthsview] = useState(false);
  const handleMonthView = (rowData) => {
    setMonthViewtData(rowData);
    setShowMonthsview(true);
  };

  // PROGRESS TOP LOADER
  const loadingBarRef = useRef(null);
  useEffect(() => {
    if (loadingBarRef.current) {
      // Start the loading bar
      loadingBarRef.current.continuousStart();

      // Simulate a load completion or manage dynamically
      const timer = setTimeout(() => {
        loadingBarRef.current.complete();
      }, 3000); // Adjust timing as needed

      // Cleanup timer
      return () => clearTimeout(timer);
    }
  }, []);

  return (

    <React.Fragment>
      <Header />
      <MonthsviewModal show={showMonthsview} closeFunction={() => setShowMonthsview(false)} data={monthViewData} />
      <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Timesheet</h4>
          </div>
        </div>

        <Row className="g-3 mb-3">
        {loading.isLoading ? (
            <ShimmerLoader colNames={["Label", "Value"]} colsCount={cardData.length} />
          ) : (
          cardData?.map((card, index) => (
            <Col key={index}>
              <Card className="card-one">
                <Card.Body>
                  <Card.Title as="label" className="fs-sm fw-medium mb-1">
                    {card.label}
                  </Card.Title>
                  <h3 className="card-value mb-1">
                    <i className={card?.icon}></i> {card?.value}
                  </h3>
                  <small>
                    <span
                      className={
                        "d-inline-flex text-" +
                        (card?.status === "up" ? "success" : "danger")
                      }
                    >
                      {card?.percent}%{" "}
                      <i
                        className={
                          "ri-arrow-" +
                          (card?.status === "up" ? "up" : "down") +
                          "-line"
                        }
                      ></i>
                    </span>
                  </small>
                </Card.Body>
              </Card>
            </Col>
          ))
        )}
        </Row>
        <Card className="card-one">
  {loading.isLoading ? (
    <ShimmerLoader colNames={columns.map((col) => col.name)} colsCount={columns.length} />
  ) : (
    <>
      <Card.Header className="gap-3 justify-content-end">
        <Form.Group controlId="clientSelect" className="wt-300">
          <Form.Label>Client</Form.Label>
          <Form.Select onChange={handleClientChange} aria-label="Select Client">
            <option>Choose Client</option>
            {clientList?.map((client) => (
              <option key={client?.id} value={client?.id}>
                {client?.clienName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        {/* Repeat similar structure for Subscription and Month selectors */}
        <Button
          type="Submit"
          variant="primary"
          className="d-flex align-items-center gap-2 mt-4"
          onClick={HandleSubmit}
          disabled={loading.isLoading}
        >
          <i className="ri-filter-line fs-18 lh-1"></i>
          <span>Filter</span>
        </Button>
      </Card.Header>
      <Card.Body>
        {FinanceTimeSheetList.length ? (
          <DataTable
            columns={columns}
            data={FinanceTimeSheetList}
            fixedHeader
            highlightOnHover
            pagination
            paginationPerPage={10}
          />
        ) : (
          <h5 className="text-center">No data available</h5>
        )}
      </Card.Body>
    </>
  )}
</Card>
       
      </div>
    </React.Fragment>
  )
};