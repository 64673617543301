import ToastifyService from "../../view/_common/ToastifyService";
import {
  ADD_DAYWISE_DATA_BY_ID,
  ADD_DAYWISE_PREDICATION_DATA_BY_ID,
  ADD_TIME_SHEET,
  ADD_TIMESHEET_STATUS,
  AddTimeSheetSuccess,
  GET_CLIENTS_List_BY_ID,
  GET_CONTINCY_LIST_BY_ID,
  GET_MAPPED_CONTRACTYPE_BY_ID,
  GET_MONTHS_List,
  GET_PAST_TIME_SHEET_COUNT,
  GET_PAST_TIME_SHEET_DETAILS,
  GET_PREDICATION_DAYWISE_BY_ID,
  GET_PREDICATION_DETAILS_BY_ID,
  GET_TIME_SHEET_COUNT,
  GET_TIME_SHEET_DAYWISE_BY_ID,
  GET_TIME_SHEET_DETAILS,
  GetClientSuccessList,
  GetContincyListSuccess,
  GetMappedContractTypeByIdSuccessList,
  GetMonthsSuccess,
  GetPastTimeSheetCountSuccess,
  GetPastTimeSheetDetailsSuccess,
  GetPredictionDayWiseSuccessList,
  GetPredictionDetailuccessList,
  GetTimeSheetCountSuccess,
  GetTimeSheetDayWiseSuccessList,
  GetTimeSheetDetailsSuccess,
  ShowCreateTimeSheetModal,
} from "../actions/timeSheetAction";


const GetTimeCount =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
 if (action.type === GET_TIME_SHEET_COUNT) {
      
      try {
        const getTimeSheetCOunt = await api.timeSheetAPI.GetCount(action.payload);
        dispatch(GetTimeSheetCountSuccess(getTimeSheetCOunt));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
const GetTimeDetails =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_TIME_SHEET_DETAILS) {
      try {
        const getTimeSheetDeta = await api.timeSheetAPI.GetTimeSheetDetails(action.payload);
        dispatch(GetTimeSheetDetailsSuccess(getTimeSheetDeta));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
const GetMonths =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_MONTHS_List) {
      try {
        const getMonthDeta = await api.timeSheetAPI.GetMonthWise(
          action.payload
        );
        dispatch(GetMonthsSuccess(getMonthDeta));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
const GetClientId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_CLIENTS_List_BY_ID) {
      try {
        const getdata = await api.timeSheetAPI.GetClientById(action.payload);
        dispatch(GetTimeSheetDetailsSuccess(getdata));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
const AddTimeSheet =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_TIME_SHEET) {
      try {
        const getdata = await api.timeSheetAPI.AddTime_Sheet(action.payload);
         const getTimeSheetCOunt = await api.timeSheetAPI.GetCount(action.payload.CreatedBy);
        if (getdata.statusCode === "201") {
          dispatch(ShowCreateTimeSheetModal(false));
          ToastifyService.success("Saved!");
          window.location.href="/OpsReviewer/timesheets";
        } else {
          dispatch(ShowCreateTimeSheetModal(true));
          ToastifyService.error("Already Exist!");
        }
        dispatch(GetTimeSheetCountSuccess(getTimeSheetCOunt));
        dispatch(GetTimeSheetDetailsSuccess(getdata));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
const GetDayWiseId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_TIME_SHEET_DAYWISE_BY_ID) {
      try {
        const getDayWise = await api.timeSheetAPI.GetTimeSheetDayWiseById(
          action.payload
        );
        dispatch(GetTimeSheetDayWiseSuccessList(getDayWise));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
const GetPreDayWiseId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_PREDICATION_DAYWISE_BY_ID) {
      try {   
        const getpreDayWise = await api.timeSheetAPI.GetPredicationDayWiseById(
          action.payload
        );
        dispatch(GetPredictionDayWiseSuccessList(getpreDayWise));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
const GetPreDetailsId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_PREDICATION_DETAILS_BY_ID) {
      try {   
        const getpreDetails = await api.timeSheetAPI.GetPredicationDetailsId(
          action.payload
        );
        dispatch(GetPredictionDetailuccessList(getpreDetails));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
const GetContractTypesId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_MAPPED_CONTRACTYPE_BY_ID) {
      try {
        const getcontracttypes =
          await api.timeSheetAPI.GetMappedContractTypesById(action.payload);
        dispatch(GetMappedContractTypeByIdSuccessList(getcontracttypes));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };

const AddDataDayWise =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_DAYWISE_DATA_BY_ID) {
      try {
        await api.timeSheetAPI.Add_DayWise_By_Id(action.payload.data1);
        const getDayWise = await api.timeSheetAPI.GetTimeSheetDayWiseById(
          action.payload.initialFetchData
        );
        dispatch(GetTimeSheetDayWiseSuccessList(getDayWise));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
const AddDataDayWisePre =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_DAYWISE_PREDICATION_DATA_BY_ID) {
      try {

        await api.timeSheetAPI.Add_DayWise_Pred_By_Id(action.payload.data1);
        const getDayWise = await api.timeSheetAPI.GetPredicationDayWiseById(
          action.payload.initialFetchData
        );
        dispatch(GetPredictionDayWiseSuccessList(getDayWise));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
const AddTimeSheetStatu =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_TIMESHEET_STATUS) {
      try {
        const getDayWise = await api.timeSheetAPI.AddTime_Sheet_Status(action.payload);
        const getTimeSheetDeta = await api.timeSheetAPI.GetTimeSheetDetails();
        dispatch(GetPredictionDayWiseSuccessList(getTimeSheetDeta));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };


  const GetPastTimeCount =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
 if (action.type === GET_PAST_TIME_SHEET_COUNT) {
      
      try {
        const getTimeSheetCOunt = await api.timeSheetAPI.GetPastCount(action.payload);
        dispatch(GetPastTimeSheetCountSuccess(getTimeSheetCOunt));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
const GetPastTimeDetails =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_PAST_TIME_SHEET_DETAILS) {
      try {
        const getTimeSheetDeta = await api.timeSheetAPI.GetPastTimeSheetDetails(action.payload);
        dispatch(GetPastTimeSheetDetailsSuccess(getTimeSheetDeta));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };

  const GetContincy =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_CONTINCY_LIST_BY_ID) {
      try {
        debugger
        const getcontincylist = await api.timeSheetAPI.GetContincyListBYId(action.payload);
        dispatch(GetContincyListSuccess(getcontincylist));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };

export default [
  GetTimeCount,
  GetTimeDetails,
  GetMonths,
  GetClientId,
  AddTimeSheet,
  GetDayWiseId,
  GetPreDetailsId,
  GetPreDayWiseId,
  GetContractTypesId,
  AddDataDayWise,
  AddDataDayWisePre,
  AddTimeSheetStatu,
  GetPastTimeCount,
  GetPastTimeDetails,
  GetContincy,
];
