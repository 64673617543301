import React, { useEffect, useRef, useState } from "react";
import { Badge, Button, Card, Dropdown, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import accessLevelData from "../../../Json/Reviewer/user-setip/access-level.json";
import MappedUserModal from "./mapped-user"
import LoadingBar from "react-top-loading-bar";
import AccessLevelView from "./access-level-vew";

export default function AccessLevel() {
    const columns = [
        {
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Status",
            selector: (row) => (
                <Badge bg={`${row.status == "Active" ? "success": "danger"}`} pill>{row.status}</Badge>
            ),
            sortable: true,
        },
        {
            name: "User Group",
            selector: (row) => (
                <div className="d-flex flex-wrap gap-1 pt-2 pb-2">
                    {row.user_group.map((group, index) => (
                        <Badge key={index} bg="primary" pill className="me-1">
                            {group}
                        </Badge>
                    ))}
                </div>
            ),
            sortable: true,
        },
        {
            name: "User Count",
            selector: (row) => (<Badge bg="primary" pill>{row.user_count}</Badge>),
            sortable: true,
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex gap-2 justify-content-end mt-md-0 align-items-center">
                    <Button variant="secondary" className="btn-icon" onClick={() => handleAccessLevel(row)}>
                        <i className="ri-eye-line"></i>
                    </Button>

                    {/* <Dropdown>
                        <Dropdown.Toggle variant="outline-dark" className="btn-icon custom-dropdown-action">
                            <i className="ri-more-2-fill fs-18"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="#" onClick={() => handleMappedUserModal(true)}>
                                <i className="ri-user-line align-middle"></i> <span className="align-middle">Mapped User</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}
                </div>
            ),
        },
    ];

    // HANDLE ACCESS LEVEL VIEW MODAL
    const [showAccessLevelModal, setShowAccessLevelModal] = useState(false);
    const [accessLevelViewData, setAccessLevelViewData] = useState(null);

    const handleAccessLevel = (row) => {
        setAccessLevelViewData(row);
        setShowAccessLevelModal(true);
    }

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredEmployees = accessLevelData?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // HANDLE MAPPED USER MODAL
    const [showMappedUserModal, setShowMappedUserModal] = useState(false);
    const handleMappedUserModal = (state) => {
        setShowMappedUserModal(state);
    }


    // PROGRESS TOP LOADER
    const loadingBarRef = useRef(null);
    useEffect(() => {
        if (loadingBarRef.current) {
            // Start the loading bar
            loadingBarRef.current.continuousStart();

            // Simulate a load completion or manage dynamically
            const timer = setTimeout(() => {
                loadingBarRef.current.complete();
            }, 3000); // Adjust timing as needed

            // Cleanup timer
            return () => clearTimeout(timer);
        }
    }, []);

    return (
        <React.Fragment>
            <MappedUserModal show={showMappedUserModal} closeFunction={handleMappedUserModal} />
            <AccessLevelView
                show={showAccessLevelModal}
                closeFunction={() => setShowAccessLevelModal(false)}
                data={accessLevelViewData}
            />
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="d-md-flex align-items-center justify-content-between mb-3">
                <h3 className="fs-14 mb-1">Access Level</h3>
            </div>

            <Card className="card-one">
                <Card.Header className="align-items-center justify-content-between">
                    <h4 className="main-title fs-14 mb-0">List of Access Level</h4>
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-300">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="custom-common-table">
                        <DataTable
                            columns={columns}
                            data={filteredEmployees}
                            fixedHeader
                            search={true}
                            highlightOnHover
                            pagination
                        ></DataTable>
                    </div>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}