import {
  ADD_FINANCE_TIME_SHEET_STATUS,
  ADD_INVOICE_DATA,
  ADD_PAYMENT_DATA,
  GET_APP_CLIENTS_List_BY_ID,
  GET_FINANACE_TIME_SHEET_DETAILS,
  GET_FINANACE_TIME_SHEET_Invoice,
  GET_INVOICE,
  GET_INVOICE_DATA,
  GET_INVOICE_PAID_DATA,
  GET_PAST_FINANACE_TIME_SHEET_DETAILS,
  GET_PAST_TIME_SHEET_FINANACE_COUNT,
  GET_TIME_SHEET_FINANACE_COUNT,
  GET_UNBILLED_INVOICE,
  GetFinanceTimeSheetDetailsSuccess,
  GetInvoiceDataSuccess,
  GetInvoicePaidDataSuccess,
  GetInvoiceSuccess,
  GetPastTFinanceTimeSheetDetailsSuccess,
  GetPastTimeSheetFinanceSuccess,
  GetTFinanceTimeSheetInvoiceSuccess,
  GetTimeSheetFinanceSuccess,
  GetUnilledInvoiceSuccess,
  UPDATE_INVOICE_DATA,
} from "../actions/financeTimeSheetAction";

const GetTimeCount =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_TIME_SHEET_FINANACE_COUNT) {
      try {
        const getTimeSheetCountFin = await api.financeAPI.GetCount(
          action.payload
        );
        dispatch(GetTimeSheetFinanceSuccess(getTimeSheetCountFin));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
const GetFinanceList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_FINANACE_TIME_SHEET_DETAILS) {
      try {
        const getFinanTimeSheetFina = await api.financeAPI.GetFinanceCientList(
          action.payload
        );
        dispatch(GetFinanceTimeSheetDetailsSuccess(getFinanTimeSheetFina));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };

const GetClientId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_APP_CLIENTS_List_BY_ID) {
      try {
        const getdata = await api.financeAPI.GetClientById(action.payload);
        dispatch(GetFinanceTimeSheetDetailsSuccess(getdata));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };

const AddTimeSheetStatu =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_FINANCE_TIME_SHEET_STATUS) {
      try {
        const getDayWise = await api.financeAPI.AddFinanceTime_Sheet_Status(
          action.payload
        );
        const getFinanTimeSheetFina =
          await api.financeAPI.GetFinanceCientList();
        dispatch(GetFinanceTimeSheetDetailsSuccess(getFinanTimeSheetFina));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };

const GetPastTimeCount =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_PAST_TIME_SHEET_FINANACE_COUNT) {
      try {
        const getpastTimeSheetCountFin = await api.financeAPI.GetpastCount(
          action.payload
        );
        dispatch(GetPastTimeSheetFinanceSuccess(getpastTimeSheetCountFin));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
const GetPastFinanceList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_PAST_FINANACE_TIME_SHEET_DETAILS) {
      try {
        const getpastFinanTimeSheetFina =
          await api.financeAPI.GetPastFinanceCientList(action.payload);
        dispatch(
          GetPastTFinanceTimeSheetDetailsSuccess(getpastFinanTimeSheetFina)
        );
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };

  const GetFinanceInvoiceData =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_FINANACE_TIME_SHEET_Invoice) {
      try {
        const getFinanTimeSheetInv = await api.financeAPI.GetFinanceInvoiceDetails(action.payload);
        dispatch(GetTFinanceTimeSheetInvoiceSuccess(getFinanTimeSheetInv));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
  const AddInvoice =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_INVOICE_DATA) {
      try {
        const getFinanTimeSheetInv = await api.financeAPI.AddInvoiceAPI(action.payload);
        const getFinanTimeSheetFina = await api.financeAPI.GetFinanceCientList(
          action.payload?.createdBy
        );
        const getInviceDet = await api.financeAPI.GetInvoiceDetailsAPI(action.payload);
        dispatch(GetInvoiceSuccess(getInviceDet));
        dispatch(GetFinanceTimeSheetDetailsSuccess(getFinanTimeSheetFina));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
  const UpdateInvoice =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_INVOICE_DATA) {
      try {
        const getFinanTimeSheetInv = await api.financeAPI.UpdateInvoiceAPI(action.payload);
        const getFinanTimeSheetFina = await api.financeAPI.GetFinanceCientList(
          action.payload?.createdBy
        );
        const getInviceDet = await api.financeAPI.GetInvoiceDetailsAPI(action.payload);
        dispatch(GetInvoiceSuccess(getInviceDet));
        dispatch(GetFinanceTimeSheetDetailsSuccess(getFinanTimeSheetFina));
        const getInviceDets = await api.financeAPI.GetUnBilledInvoiceListbyStatusIdAPI(action.payload);
        dispatch(GetUnilledInvoiceSuccess(getInviceDets));
        // window.location.href="/FinanceManager/UnbilledInvoices";
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
  const GetInvoiceDet =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_INVOICE) {
      try {
        const getInviceDet = await api.financeAPI.GetInvoiceDetailsAPI(action.payload);
        dispatch(GetInvoiceSuccess(getInviceDet));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };

  const GetInvoicedataList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_INVOICE_DATA) {
      try {
        const getInviceDet = await api.financeAPI.GetInvoiceDataListbyStatusIdAPI(action.payload);
        dispatch(GetInvoiceDataSuccess(getInviceDet));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };
  const GetInvoicepaiddataList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_INVOICE_PAID_DATA) {
      try {
        const getInviceDet = await api.financeAPI.GetInvoicePaidDataListbyStatusIdAPI(action.payload);
        dispatch(GetInvoicePaidDataSuccess(getInviceDet));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };

  const GetUnBilledInvoiceList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_UNBILLED_INVOICE) {
      try {
        const getInviceDet = await api.financeAPI.GetUnBilledInvoiceListbyStatusIdAPI(action.payload);
        dispatch(GetUnilledInvoiceSuccess(getInviceDet));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };

  const AddPay =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_PAYMENT_DATA) {
      try {
        const getPaymentAPI = await api.financeAPI.AddPaymentAPI(action.payload);
        const getInviceDet = await api.financeAPI.GetInvoiceDataListbyStatusIdAPI(action.payload);
        dispatch(GetInvoiceDataSuccess(getInviceDet));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
        }
      }
    }
    next(action);
  };

export default [
  GetTimeCount,
  GetFinanceList,
  GetClientId,
  AddTimeSheetStatu,
  GetPastTimeCount,
  GetPastFinanceList,
  GetFinanceInvoiceData,
  AddInvoice,
  GetInvoiceDet,
  GetInvoicedataList,
  GetUnBilledInvoiceList,
  UpdateInvoice,
  AddPay,
  GetInvoicepaiddataList
];
