import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import useAuth from "../hooks/useAuth";


export default function Main() {
  const navigate = useNavigate();
  const { setAuth, auth } = useAuth();
  useEffect(() => {
    
    // Check if user is not authenticated
    if (!auth) {
      navigate('/account/login');  // Redirect to login page
    } 
  }, [navigate]);

 

  const offsets = [
    "/apps/file-manager", 
    "/apps/email",
    "/apps/calendar", 
    '/OpsApproval/timesheets', 
    '/OpsReviewer/timesheets', 
    "/reviewer/manage", 
    "/invoice/manage", 
    "/setting/manage", 
    "/manage/client/details", 
    "/timesheet/view", 
    "/approver/manage", 
    "/FinanceManager/timesheets",
    "/FinanceManager/timesheetsView",
    "/OpsReviewer/pastTimesheet/timesheet",
    "/OpsReviewer/PastTimesheetsView",
    "/opsApproval/pastTimesheet/timesheet",
    "/financeManager/pastTimesheet/timesheet",
    "/OpsApproval/PastTimesheetsView",
    "/management/monthView"
  ];
  const { pathname } = useLocation();
  const bc = document.body.classList;

  // set sidebar to offset
  (offsets.includes(pathname)) ? bc.add("sidebar-offset") : bc.remove("sidebar-offset");

  // auto close sidebar when switching pages in mobile
  bc.remove("sidebar-show");
 
  // scroll to top when switching pages
  window.scrollTo(0, 0);
  
  return (
    <React.Fragment>
      <Sidebar role={auth?.roles[0]} /> {/* Pass the role as a prop */}
      <Outlet />
    </React.Fragment>
  )
}
