import {
  GET_CLIENT_BY_ID_SUCCESS,
  GET_MONTHS_SUCCESS,
  GET_TIME_SHEET_COUNT_SUCCESS,
  GET_TIME_SHEET_DAYWISE_BY_ID_SUCCESS,
  GET_TIME_SHEET_DETAILS_SUCCESS,
  SHOW_CREATE_TIMESHEET_MODAL,
  GET_PREDICATION_DAYWISE_BY_ID_SUCCESS,
  GET_MAPPED_CONTRACTYPE_BY_ID_SUCCESS,
  GET_PAST_TIME_SHEET_DETAILS_SUCCESS,
  GET_PAST_TIME_SHEET_COUNT_SUCCESS,
  GET_PREDICATION_DETAILS_BY_ID_SUCCESS,
  GET_CONTINCY_LIST_SUCCESS,
} from "../actions/timeSheetAction";

const initialState = {
  coutList: [],
  getTimeSheetDetails: [],
  pastcoutList: [],
  getpastTimeSheetDetails: [],
  monthsLi: [],
  ClientListBYId: [],
  DayWiseListBYId: [],
  PredictionDetailsListBYId: [],
  PredictionDayWiseListBYId: [],
  getContincyList: [],
  ContractTypesById: {},
  error: null,
  showTimeSheetCreateModal: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TIME_SHEET_COUNT_SUCCESS:
      return { ...state, coutList: action.payload.result, error: null };
    case GET_TIME_SHEET_DETAILS_SUCCESS:
      return { ...state, getTimeSheetDetails: action.payload, error: null };
    case GET_MONTHS_SUCCESS:
      return { ...state, monthsLi: action.payload.result, error: null };
    case GET_CLIENT_BY_ID_SUCCESS:
      return { ...state, ClientListBYId: action.payload.result, error: null };
    case GET_TIME_SHEET_DAYWISE_BY_ID_SUCCESS:
      return { ...state, DayWiseListBYId: action.payload.result, error: null };
    case GET_PREDICATION_DAYWISE_BY_ID_SUCCESS:
      return { ...state, PredictionDayWiseListBYId: action.payload.result, error: null };
    case GET_PREDICATION_DETAILS_BY_ID_SUCCESS:
      return { ...state, PredictionDetailsListBYId: action.payload.result, error: null };
    case GET_MAPPED_CONTRACTYPE_BY_ID_SUCCESS:
      return { ...state, ContractTypesById: action.payload.result, error: null };
    case SHOW_CREATE_TIMESHEET_MODAL:
      return {...state, showTimeSheetCreateModal: action.payload, error: null,};
    case GET_PAST_TIME_SHEET_COUNT_SUCCESS:
      return {...state, pastcoutList: action.payload, error: null,};
    case GET_PAST_TIME_SHEET_DETAILS_SUCCESS:
      return {...state, getpastTimeSheetDetails: action.payload, error: null,};
    case GET_CONTINCY_LIST_SUCCESS:
      return {...state, getContincyList: action.payload.result, error: null,};
   
    default:
      return state;
  }
};

export default reducer;
