import React, { useEffect, useState, useRef } from "react";
import { Card, Button, Badge, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import CreateClient from "./create-client";
import SweetAlertService from "../../_common/SweetAlertService";
import ToastifyService from "../../_common/ToastifyService";
import { GetClientList } from "../../../application/actions/clientAction";
import { getAllClientList } from "../../../application/selectors/indexSelector";
import { useDispatch, useSelector } from "react-redux";
import LoadingBar from 'react-top-loading-bar';

export default function Clients() {
  const dispatch = useDispatch()
  const [selectedClientData, setSelectedClientData] = useState(null);
  const clientList = useSelector(getAllClientList);
  useEffect(() => {
    dispatch(GetClientList);
  }, []);


  const columns = [
    {
      name: "Client Name",
      selector: (row) => (
        <Link onClick={() => handleClientDetails(row)} className="fw-semibold text-wrap">
          {row.clienName}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Client Code",
      selector: (row) => row?.clientCode,
      sortable: true,
    },
    {
      name: "Cost Center",
      selector: (row) => row?.clientCenter,
      sortable: true,
    },
    {
      name: "Live Date",
      selector: (row) => row?.liveDate,
      sortable: true,
    },
    {
      name: "Onboarding Date",
      selector: (row) => row?.onboardingDate,
      sortable: true,
    },
   
    {
      name: "CreatedBy",
      selector: (row) => row?.fullName,
      sortable: true,
    },
    {
      name: "CreatedOn",
      selector: (row) => row?.createdOn,
      sortable: true,
    },
    {
      name: "Subscription",
      selector: (row) => (
        <div className="d-flex flex-wrap gap-1 pt-2 pb-2">
          {row?.subscriptionNames?.map((group, index) => (
            <Badge key={index} bg="primary" pill className="me-1">{group}</Badge>
          ))}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <Badge
          bg={row.fieldValue === "InActive" ? "danger" : "success"}
          pill
        >
          {row.fieldValue}
        </Badge>
      ),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button variant="primary" className="btn-icon me-2" onClick={() => handleClientUpdate(row)}>
            <i className="ri-pencil-line"></i>
          </Button>
         
        </div>
      ),
    },
  ];

  // SEARCH FILTER TABLE DATA
  const [searchTerm, setSearchTerm] = useState('');
  const filteredEmployees = clientList?.filter((item) =>
    Object.values(item).some((value) =>
      value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  // HANDLE CLIENT SETUP MODAL
  const [showAddClientSetup, setShowAddClientSetup] = useState(false);
  const handleClientSetup = (state) => {
    setShowAddClientSetup(state);
    // setSelectedClientData(null)
  };

  // HANDLE DELETE CLIENT
  const handleDeleteClient = (data) => {
    SweetAlertService.showAlert(
      data.clientName,
      "Are you sure you want to delete this Client?",
      "warning"
    )
      .then((result) => {
        if (result.isConfirmed) {
          ToastifyService.success(`User Deleted Successfully`);
        } else {
          console.log("Delete operation cancelled!");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        ToastifyService.error(`something went wrong`);
      });
  };

  // CLIENT DETAILS MODAL FUNCTION
  // const [clientData, setClientData] = useState(null);
  // const [showClientDetails, setShowClientDetails] = useState(false);
  const handleClientDetails = (rowData) => {
    // setShowClientDetails(true);
  };
  const handleClientUpdate = (rowData) => {

    setShowAddClientSetup(true);
    setSelectedClientData(rowData)
  };

   // PROGRESS TOP LOADER
   const loadingBarRef = useRef(null);
   useEffect(() => {
       if (loadingBarRef.current) {
           // Start the loading bar
           loadingBarRef.current.continuousStart();

           // Simulate a load completion or manage dynamically
           const timer = setTimeout(() => {
               loadingBarRef.current.complete();
           }, 3000); // Adjust timing as needed

           // Cleanup timer
           return () => clearTimeout(timer);
       }
   }, []);

  return (
    <React.Fragment>
      <CreateClient
        show={showAddClientSetup}
        closeFunction={handleClientSetup}
        data = {selectedClientData}

      />
      {/* <ClientDetails
        show={showClientDetails}
        closeFunction={() => setShowClientDetails(false)}
        clientData={clientData}
      /> */}
      <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
      <div className="d-md-flex align-items-center justify-content-between mb-3">
        <h3 className="fs-14 mb-1">Client Setup</h3>
        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button
            variant="primary"
            className="d-flex align-items-center gap-2"
            onClick={() => handleClientSetup(true)}
          >
            <i className="ri-add-line fs-18 lh-1"></i>
            <span className="d-sm-inline"> Create New Client Setup</span>
          </Button>
        </div>
      </div>

      <Card className="card-one">
        <Card.Header className="align-items-center justify-content-between">
          <h4 className="main-title fs-14 mb-0">List of Client</h4>
          <div className="custom-dropdown-wrapper">
            <div className="custom-drop-down z-index-2 wt-300">
              <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
              <Form.Control
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <DataTable
            columns={columns}
            data={filteredEmployees}
            fixedHeader
            search={true}
            highlightOnHover
            pagination
          ></DataTable>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
