import React from "react";

import Dashboard from "../view/Dashboard/dashboard";
import Reviewer from "../view/Reviewer/reviewer";
import Timesheet from "../view/Timesheets/manage";
import MonthSheetView from "../view/Timesheets/monthsheet";
import Projection from "../view/Projection/manage";
import Approver from "../view/Approver/manage";
import FinanceManager from "../view/FinanaceManager/manage";
import Management from "../view/Managment/manage";
import ManagementMonthView from "../view/Managment/months/months-wise";
import SystemSettings from "../view/setting/manage";
import ClientDetails from "../view/setting/Client/client-details";
import InvoiceTemplate from "../view/Invoicetemplate/manage";
import CreateTemplate from "../view/Invoicetemplate/create-template";

// FINANCE MANAGER PAGES
import FinanceManagerTimesheet from "../view/FinanceManager/pages/Timesheet/timesheet";
import FinanceViewPastTimesheet from "../view/FinanceManager/pages/PastTimesheet/view-past-timesheet";
import FinanceManagerPastTimesheet from "../view/FinanceManager/pages/PastTimesheet/past-timesheet";
import FinanceManagerUnbilledInvoice from "../view/FinanceManager/pages/UnbilledInvoices/unbilled-invoices";
import FinanceManagerUnpaidInvoice from "../view/FinanceManager/pages/UnpaidInvoice/unpaid-invoice";
import FinanceManagerPaymentUpdate from "../view/FinanceManager/pages/PaymentUpdate/payment-update";

// OPS REVIEWER PAGES
import OpsReviewerTimesheet from "../view/OpsReviewer/pages/Timesheet/timesheet";
import OpsReviewerPastTimesheet from "../view/OpsReviewer/pages/PastTimesheet/past-timesheet";
import OpsReviewerViewPastTimesheet from "../view/OpsReviewer/pages/PastTimesheet/view-past-timesheet";
import OpsReviewerProjection from "../view/OpsReviewer/pages/Projection/projection";

// OPS APPROVAL PAGES
import OpsApprovalTimesheet from "../view/OpsApproval/pages/Timesheet/timesheet";
import OpsApprovalPastTimesheet from "../view/OpsApproval/pages/PastTimesheet/past-timesheet";
import OpsApperovalViewPastTimesheet from "../view/OpsApproval/pages/PastTimesheet/view-past-timesheet";
import OpsApprovalUnbilledInvoice from "../view/OpsApproval/pages/UnbilledInvoices/unbilled-invoices";
import OpsApprovalUnpaidInvoice from "../view/OpsApproval/pages/UnpaidInvoice/unpaid-invoice";

const protectedRoutes = [
  //{ path: "", element: <Dashboard /> },
  { path: "dashboard/manage", element: <Dashboard /> },
  { path: "reviewer/manage", element: <Reviewer /> },
  { path: "timesheet/manage", element: <Timesheet /> },
  { path: "timesheet/view", element: <MonthSheetView /> },
  { path: "projection/manage", element: <Projection /> },
  { path: "approver/manage", element: <Approver /> },
  { path: "finanaceManager/manage", element: <FinanceManager /> },
  { path: "management/manage", element: <Management /> },
  { path: "management/monthView", element: <ManagementMonthView /> },
  { path: "setting/manage", element: <SystemSettings /> },
  { path: "manage/client/details", element: <ClientDetails /> },
  { path: "invoicetemplate/manage", element: <InvoiceTemplate /> },
  { path: "invoicetemplate/manage/create", element: <CreateTemplate /> },

  // FINANCE MANAGE PAGES
  { path: "FinanceManager/timesheets", element: <FinanceManagerTimesheet /> },
  { path: "FinanceManager/timesheetsView", element: <FinanceViewPastTimesheet /> },
  { path: "FinanceManager/PastTimesheets", element: <FinanceManagerPastTimesheet /> },
  { path: "FinanceManager/UnbilledInvoices", element: <FinanceManagerUnbilledInvoice /> },
  { path: "FinanceManager/UnpaidInvoices", element: <FinanceManagerUnpaidInvoice /> },
  { path: "FinanceManager/PaymentUpdate", element: <FinanceManagerPaymentUpdate /> },

  
  // OPS REVIEWER PAGES
  { path: "OpsReviewer/timesheets", element: <OpsReviewerTimesheet /> },
  { path: "OpsReviewer/PastTimesheets", element: <OpsReviewerPastTimesheet /> },
  { path: "OpsReviewer/PastTimesheetsView", element: <OpsReviewerViewPastTimesheet /> },
  { path: "OpsReviewer/Projection", element: <OpsReviewerProjection /> },

  // OPS APPROVAL PAGES
  { path: "OpsApproval/timesheets", element: <OpsApprovalTimesheet /> },
  { path: "OpsApproval/PastTimesheets", element: <OpsApprovalPastTimesheet /> },
  { path: "OpsApproval/PastTimesheetsView", element: <OpsApperovalViewPastTimesheet /> },
  { path: "OpsApproval/UnbilledInvoices", element: <OpsApprovalUnbilledInvoice /> },
  { path: "OpsApproval/UnpaidInvoices", element: <OpsApprovalUnpaidInvoice /> },
]

export default protectedRoutes;