import React, { useEffect, useState, useRef } from "react";
import { Badge, Button, Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
//import SubcriptionsData from "../../../Json/Setting/Subcriptions.json";
import CreateSubscription from "./create-subscription";
import { useDispatch, useSelector } from "react-redux";
import { getSubscrtiptionData } from "../../../application/selectors/indexSelector";
import { GetSubscription } from "../../../application/actions/subscriptionAction";
import LoadingBar from "react-top-loading-bar";
import DetailSubscription from "./subscriptio-details";

export default function Subscription() {
  const dispatch = useDispatch();
  const SubscriptionList = useSelector(getSubscrtiptionData);
  useEffect(() => {
    dispatch(GetSubscription);
  }, []);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.subsName,
      sortable: true,

    },
    {
      name: "Contract Type",
      selector: (row) => row.contractName,
      sortable: true,
    },
    // {
    //   name: "Start Date",
    //   selector: (row) => row.startDate,
    //   sortable: true,
    // },
    // {
    //   name: "Term Date",
    //   selector: (row) => row.endDate,
    //   sortable: true,
    // },
    {
      name: "Billing Status",
      selector: (row) => (
        <Badge
          bg={row.billableStatus === "Non-Billable" ? "danger" : "success"}
          pill
        >
          {row.billableStatus}
        </Badge>
      ),
      sortable: true,
    },
    // {
    //   name: "Monthly Minimum",
    //   selector: (row) => row.monthlyMinimum,
    //   sortable: true,
    // },
    {
      name: "Operations Reviewer",
      selector: (row) => (
        <div className="d-flex flex-wrap gap-1 pt-2 pb-2">
          {row?.reviewer?.map((group, index) => (
            <Badge key={index} bg="primary" pill className="me-0">{group}</Badge>
          ))}
        </div>
      ),
      sortable: true,
      width: "200px",

    },
    {
      name: "Operations Approver",
      selector: (row) => (
        <div className="d-flex flex-wrap gap-1 pt-2 pb-2">
          {row?.approver?.map((group, index) => (
            <Badge key={index} bg="primary" pill className="me-1">{group}</Badge>
          ))}
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Finanace Manager",
      selector: (row) => (
        <div className="d-flex flex-wrap gap-1 pt-2 pb-2">
          {row?.manager?.map((group, index) => (
            <Badge key={index} bg="primary" pill className="me-1">{group}</Badge>
          ))}
        </div>
      ),
      sortable: true,
      width: "200px",
    },

    // {
    //   name: "Status",
    //   selector: (row) => (
    //     <Badge bg={row.fieldValue === "InActive" ? "danger" : "success"} pill>
    //       {row.fieldValue}
    //     </Badge>
    //   ),
    //   sortable: true,
    // },
    {
      name: "Created By",
      selector: (row) => row.createdByName,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end gap-2">
          <Button
            variant="secondary"
            className="btn-icon"
            onClick={() => handleSubscriptionView(row)}>
            <i className="ri-eye-line"></i>{" "}
          </Button>
          <Button
            variant="primary"
            className="btn-icon me-0"
            onClick={() => handleUpdateSubscription(row)}
          >
            <i className="ri-pencil-line"></i>
          </Button>
        </div>
      ),
    },
  ];


  // SEARCH FILTER TABLE DATA
  const [searchTerm, setSearchTerm] = useState("");
  // const filteredEmployees = SubcriptionsData?.filter((item) =>
  //   Object.values(item).some(
  //     (value) =>
  //       value &&
  //       value.toString().toLowerCase().includes(searchTerm.toLowerCase())
  //   )
  // );

  const [showCreateSubscription, setShowCreateSubscription] = useState(false);
  const handleCreateSubscription = (state) => {
    setShowCreateSubscription(state);
  };

  const [subscriptionFieldvalue, setSubscriptionFieldValue ] = useState(null)
  const handleUpdateSubscription = (data)=> {
    setSubscriptionFieldValue(data)
    setShowCreateSubscription(true);

  }

  // PROGRESS TOP LOADER
  const loadingBarRef = useRef(null);
  useEffect(() => {
    if (loadingBarRef.current) {
      // Start the loading bar
      loadingBarRef.current.continuousStart();

      // Simulate a load completion or manage dynamically
      const timer = setTimeout(() => {
        loadingBarRef.current.complete();
      }, 3000); // Adjust timing as needed

      // Cleanup timer
      return () => clearTimeout(timer);
    }
  }, []);

  const [SubscriptionData, setSubscriptionData] = useState(null);
  const [showSubscriptionDetails, setShowSubscriptionDetails] = useState(false);
  const handleSubscriptionView = (row)=> {
    setShowSubscriptionDetails(true);
    setSubscriptionData(row);
  }

  return (
    <React.Fragment>
      <CreateSubscription show={showCreateSubscription} closeFunction={handleCreateSubscription}
       data={subscriptionFieldvalue} />
      <DetailSubscription show={showSubscriptionDetails} closeFunction={() => setShowSubscriptionDetails(false)} data={SubscriptionData} />
      <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <h4 className="fs-14">Subscription</h4>
        </div>
        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button variant="primary" className="d-flex align-items-center gap-2" onClick={() => handleCreateSubscription(true)}>
            <i className="ri-add-line fs-18 lh-1"></i><span className="d-sm-inline">Create Subscription</span>
          </Button>
        </div>
      </div>
      <Card className="card-one">
        <Card.Header className="align-items-center justify-content-between">
          <h4 className="main-title fs-14 mb-0">List of Subscription</h4>
          <div className="custom-dropdown-wrapper">
            <div className="custom-drop-down z-index-2 wt-300">
              <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
              <Form.Control
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <DataTable
            columns={columns}
            data={SubscriptionList}
            fixedHeader
            search={true}
            highlightOnHover
            pagination
          ></DataTable>
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}
