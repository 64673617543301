import React, { useEffect, useState, useRef } from "react";
import Header from "../../../../layouts/Header";
//import projectionlist from "../../../../Json/Projection/projection.json";
import { Button, Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import WeeksModal from "./weeks-wise";
import useAuth from "../../../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
//import { useNavigate } from "react-router-dom";
import { GetPredictionDetailsBYId } from "../../../../application/actions/timeSheetAction";
import { getPredictionDeatailsListById } from "../../../../application/selectors/indexSelector";
import LoadingBar from "react-top-loading-bar";

export default function OpsReviewerProjection() {
    const [projectionData, setProjectionData] = useState(null);
    const [showWeeksSheet, setShowWeeksSheet] = useState(false);
    const { setAuth, auth } = useAuth();
    const [weeklyData, setWeeklyData] = useState([]);
    const dispatch = useDispatch();
    const ProjectList = useSelector(getPredictionDeatailsListById);

    //const navigate = useNavigate();
    // const TimeSheetList1 = useSelector(getClientsListById);
    // useEffect(() => {
    //   if (TimeSheetList) {
    //     setTimeSheetList(TimeSheetList); // Set on load or TimeSheetList change
    //   }
    // }, [TimeSheetList]);
    // useEffect(() => {
    //   if (TimeSheetList1) {
    //     setTimeSheetList(TimeSheetList1); // Set when client list changes
    //   }
    // }, [TimeSheetList1]);

    useEffect(() => {
        dispatch(GetPredictionDetailsBYId(auth?.id));
    }, []);

    // Handle action button click
    const handleWeeksSheet = (rowData) => {
        // const filteredWeeklyData = getWeeklyData(rowData.month_data); // Filter data for the selected row
        setProjectionData(rowData); // Set the row data
        //setWeeklyData(filteredWeeklyData); // Set the filtered weekly data
        setShowWeeksSheet(true); // Show the modal
    };

    // Columns configuration for DataTable
    const columns = [
        {
            name: "Months",
            selector: (row) => (
                <span
                    onClick={() => handleWeeksSheet(row)}
                    style={{
                        cursor: "pointer !important",
                        color: "#506fd9",
                        textDecoration: "none"

                    }}
                >
                    {row.months}
                </span>
            ),
            sortable: true,
        },
        {
            name: "Client/Subscription",
            selector: (row) => (
                <div>
                    <h5 className="fs-14 mb-1">{row.clienName} - {row.subsName}</h5>
                </div>
            ),
            sortable: true,
        },
        {
            name: "Contract Type",
            selector: (row) => (
                <div>
                    <h5 className="fs-14 mb-1">{row.contractName}</h5>
                </div>
            ),
            sortable: true,
        },
        {
            name: "Action",
            selector: (row) => (
                <Button
                    variant="outline-dark"
                    className="btn-icon"
                    onClick={() => handleWeeksSheet(row)}
                >
                    <i className="ri-eye-line"></i>
                </Button>
            ),
        },
    ];

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredEmployees = ProjectList?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // PROGRESS TOP LOADER
    const loadingBarRef = useRef(null);
    useEffect(() => {
        if (loadingBarRef.current) {
            // Start the loading bar
            loadingBarRef.current.continuousStart();

            // Simulate a load completion or manage dynamically
            const timer = setTimeout(() => {
                loadingBarRef.current.complete();
            }, 3000); // Adjust timing as needed

            // Cleanup timer
            return () => clearTimeout(timer);
        }
    }, []);

    return (
        <React.Fragment>
            <Header />
            <WeeksModal
                show={showWeeksSheet}
                closeFunction={() => setShowWeeksSheet(false)}
                data={projectionData}
                weeklyData={weeklyData} // Pass weekly data to modal
            />
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0">Projection</h4>
                    </div>
                </div>

                <Card className="card-one">
                    <Card.Header className="align-items-center justify-content-between">
                        <h4 className="main-title fs-14 mb-0">List of Projection</h4>
                        <div className="custom-dropdown-wrapper">
                            <div className="custom-drop-down z-index-2 wt-300">
                                <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                                <Form.Control
                                    type="text"
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <DataTable
                            columns={columns}
                            data={filteredEmployees}
                            fixedHeader
                            highlightOnHover
                            pagination
                            paginationPerPage={12}
                        />
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    )
};