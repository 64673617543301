import React, { useState, useEffect } from "react";
import { Badge, Form, Offcanvas } from "react-bootstrap";

export default function UserDirectoryDetails(props) {
    const [directoryData, setDirectoryData] = useState(null);

    // UseEffect to set the directoryData when props.data changes
    useEffect(() => {
        if (props.data) {
            setDirectoryData(props.data);
        }
    }, [props.data]);  // Ensure props.data is a dependency

    // Safely split fullName into role and email
    const fullName = directoryData?.fullName || "";
    const [role, email] = fullName.split(" ");

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-30">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">User Directory Details</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {directoryData ? (
                        <div>
                            <div className="product-wrapper p-2 mb-3">
                                <Form.Label className="mb-0">Role</Form.Label>
                                <div className="fs-14 fw-semibold">{role}</div>
                            </div>

                            <div className="product-wrapper p-2 mb-3">
                                <Form.Label className="mb-0">Email</Form.Label>
                                <div className="fs-14 fw-semibold">{email}</div>
                            </div>

                            <div className="product-wrapper p-2 mb-3">
                                <Form.Label className="mb-0">User Role</Form.Label>
                                <div className="fs-14 fw-semibold">{directoryData.roleName}</div>
                            </div>

                            <div className="product-wrapper p-2 mb-3">
                                <Form.Label className="mb-0">DOP</Form.Label>
                                <div className="fs-14 fw-semibold">{directoryData.dob}</div>
                            </div>

                            <div className="product-wrapper p-2 mb-3">
                                <Form.Label className="mb-0">Status</Form.Label>
                                <div className="fs-14 fw-semibold">
                                    <Badge className={`${directoryData.status === "Active" ? "bg-success" : "bg-danger"}`} pill>{directoryData.status}</Badge>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p>No data available.</p>
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    );
}
