import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Row, Col, Form, Image } from "react-bootstrap";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import {
  getAllClientmasterById,
  getAllClientmasterList,
  getSubscrtiptionData,
} from "../../../application/selectors/indexSelector";
import {
  AddClient,
  AddImage,
  GetClientMasterById,
  GetClientMasterList,
} from "../../../application/actions/clientAction";
import { useDispatch, useSelector } from "react-redux";
import { GetSubscription } from "../../../application/actions/subscriptionAction";
import Select from "react-select";
import useAuth from "../../../hooks/useAuth";
import Environment from "../../../infrastructure/core/Environment";

export default function CreateClient(props) {
  const { setAuth, auth } = useAuth();
  const dispatch = useDispatch();
  const [Masterclient, setMasterclient] = useState(null);
  const [clientCode, setclientCode] = useState(null);
  const [clientCenter, setclientCenter] = useState(null);
  const [liveDate, setLiveDate] = useState("");
  const [onboardingDate, setOnboardingDate] = useState("");
  const [clientEmailList, setClientEmailList] = useState("");
  const [credenceEmailList, setCredenceEmailList] = useState("");
  const [subscribedList, setSubscribedList] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const ClietMasterList = useSelector(getAllClientmasterList);
  const ClietMasterById = useSelector(getAllClientmasterById);
  const SubscriptionList = useSelector(getSubscrtiptionData);
  const [file, setFile] = useState(null); 
//   const inititalValues = { :TODO:
    
//     commonFieldType: "",
//     fieldName: "",
//     description: ""
// }
//   const [formData, setFormData] = useState(inititalValues)


  useEffect(() => {
    dispatch(GetClientMasterList);
    dispatch(GetSubscription);
  }, [dispatch]);

  const handleClientChange = (event) => {
    const selectedId = event?.target?.value;
    setMasterclient(selectedId);
    console.log("Id", Masterclient);
    if (Masterclient !== "Choose Client") {
      dispatch(GetClientMasterById(Masterclient));
      setclientCenter(ClietMasterById[0]?.clientCenter || "");
      setclientCode(ClietMasterById[0]?.clientCode || "");
    } else {
      setclientCenter(null);
      setclientCode(null);
    }
  };

  const handleLiveDateChange = (e) => {
    setLiveDate(e.target.value);
  };

  const handleOnboardingDateChange = (e) => {
    setOnboardingDate(e.target.value);
  };

  const handleClientEmailChange = (e) => {
    setClientEmailList(e.target.value);
  };

  const handleSubscriptionChange = (selectedOptions) => {
    console.log("Selected Subscriptions:", selectedOptions);
    setSubscribedList(selectedOptions || []);
  };

  const handleCredenceEmailChange = (e) => setCredenceEmailList(e.target.value);
  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setSelectedImage(URL.createObjectURL(selectedFile));
      setFile(selectedFile); 
    }
  };
  const Sublist = SubscriptionList?.map((sub, index) => ({
    value: sub?.id,
    label: sub?.subsName + " - " + sub?.contractName,
  }));

  const handleSubmit = (e) => {


    e.preventDefault();
    const data = {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      status: "5ed68f5c-db32-4841-8aa1-99b1f8721100",
      createdBy: auth?.id,
      modifiedBy: auth?.id,
      mClientId: Masterclient,
      liveDate: liveDate,
      onboardingDate: onboardingDate,
      subscriptionId: subscribedList?.map((item) => item.value), 
      clientEmailAddress: clientEmailList,
      credenceEmailAddress: credenceEmailList,
    };
    // const image = {
    //   Address: "Invoice",
    //   Name: "LOGO",
    //   despositionNumber: Masterclient,
    //   file: file,
    // };
    dispatch(AddClient(data));
    // dispatch(AddImage(image));
  };
  
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
          {props?.data ? "Update" :"Create New"} Client Setup
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col md={4}>
              <div className="mb-4">
                <Form.Label>Client Name</Form.Label>
                <Form.Select onChange={handleClientChange}>
                  <option>Choose Client</option>
                  {ClietMasterList &&
                    ClietMasterList?.length > 0 &&
                    ClietMasterList?.map((Subs) => (
                      <option key={Subs?.id} value={Subs?.id}>
                        {Subs?.clienName}
                      </option>
                    ))}
                </Form.Select>
              </div>
            </Col>
          </Row>

          {clientCode && (
            <>
              <Row>
                <Col md={4}>
                  <div className="mb-4">
                    <Form.Label>Client Code</Form.Label>
                    <Form.Control
                      type="text"
                      value={clientCode || ""}
                      disabled
                      placeholder="Enter Client Code"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="mb-4">
                    <Form.Label>Cost Center</Form.Label>
                    <Form.Control
                      type="text"
                      value={clientCenter || ""}
                      disabled
                      placeholder="Enter Cost Center"
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}

          <Row>
            <Col md={6}>
              <div className="mb-4">
                <Form.Label>Live Date</Form.Label>
                <Form.Control
                  type="date"
                  value={liveDate}
                  onChange={handleLiveDateChange}
                />
              </div>
            </Col>

            <Col md={6}>
              <div className="mb-4">
                <Form.Label>Onboarding Date</Form.Label>
                <Form.Control
                  type="date"
                  value={onboardingDate}
                  onChange={handleOnboardingDateChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className="divider divider-start">
                <span className="fs-14 fw-semibold">
                  Email Distribution List
                </span>
              </div>
              <Row>
                <Col md={8}>
                  <div className="mb-4">
                    <Form.Label>Client Email Address</Form.Label>

                    <Form.Control
                      type="text"
                      placeholder="Enter Client Name"
                      autoComplete="off"
                      value={clientEmailList}
                      onChange={handleClientEmailChange}
                    />
                  </div>
                </Col>
                <Col md={8}>
                  <div className="mb-4">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Client Name"
                      autoComplete="off"
                      value={credenceEmailList}
                      onChange={handleCredenceEmailChange}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={8}>
              <div className="mb-5">
                <Form.Label>Subscription</Form.Label>
                <Select
                  options={Sublist}
                  isSearchable={true}
                  isMulti
                  onChange={handleSubscriptionChange}
                />
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col md={2} className="d-flex justify-content-end">
              <div className="new-template-edit-view">
                <div
                  className="image-placeholder d-flex align-items-center justify-content-center"
                  onClick={() => document.getElementById("fileInput").click()}
                  style={{
                    cursor: "pointer",
                    border: "2px dashed #ccc",
                    width: "100%",
                  }}
                >
                  {selectedImage ? (
                    <Image src={selectedImage} alt="Uploaded" fluid />
                  ) : (
                    <span id="placeholder-text" className="fw-semibold">
                      <i className="ri-upload-line align-middle"></i> Upload
                      logo
                    </span>
                  )}
                  <Form.Control
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                </div>
              </div>
            </Col>
          </Row> */}
        </Offcanvas.Body>
        <div className="offcanvas-footer justify-content-start">
          <Button
            type="submit"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            onClick={handleSubmit}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">{props?.data ? "Update" :"Create"} Client</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
