import React, { useEffect, useState, useRef } from "react";
import Header from "../../../../layouts/Header";
import DataTable from "react-data-table-component";
import { Card, Row, Col, Form, Button, Badge } from "react-bootstrap";
import { GetClientSubscription } from "../../../../application/actions/subscriptionAction";
import { GetClientListById } from "../../../../application/actions/clientAction";
import useAuth from "../../../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getClientListBYId, getClientSubscrtiption, getMonthListById, getPastFinanceTimeDetailsList, getPastFinanceTimeSheetCount } from "../../../../application/selectors/indexSelector";
import { GetTimeSheetDayWiseBYId } from "../../../../application/actions/timeSheetAction";
import { GetPastTFinanceTimeSheetDetails, GetPastTimeSheetFinanceCount } from "../../../../application/actions/financeTimeSheetAction";
import LoadingBar from "react-top-loading-bar";
import ShimmerLoader from "../../../_common/ShimmerLoader";



export default function FinanceManagerPastTimesheet() {
  const { setAuth, auth } = useAuth();
  const [timesheetData, setTimesheetData] = useState(null);
  //const [weeklyData, setWeeklyData] = useState([]);
  const dispatch = useDispatch();
  const clientList = useSelector(getClientListBYId);
  const SubsclientList = useSelector(getClientSubscrtiption);
  const [clientId, setClientId] = useState(null);
  //const [subsId, setSubsId] = useState(null);
  //const [monthId, setMonthsId] = useState(null);
  //const [TimeSheetLi, setTimeSheetList] = useState([]);
  const MonthWiseList = useSelector(getMonthListById);
  const pastCountList = useSelector(getPastFinanceTimeSheetCount);
  const FinancePastTimeSheettList = useSelector(getPastFinanceTimeDetailsList);
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "getPasttimesheet",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading({ isLoading: true, value: "getPasttimesheet" });
  
        // Dispatch actions sequentially or in parallel
        await Promise.all([
          dispatch(GetClientListById(auth?.id)),
          dispatch(GetPastTimeSheetFinanceCount(auth?.id)),
          dispatch(GetPastTFinanceTimeSheetDetails(auth?.id)),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading({ isLoading: false, value: "" });
      }
    };
  
    fetchData();
  }, [auth?.id, dispatch, setLoading]);
  

  // PROGRESS TOP LOADER
  const loadingBarRef = useRef(null);
  useEffect(() => {
    if (loadingBarRef.current) {
      // Start the loading bar
      loadingBarRef.current.continuousStart();

      // Simulate a load completion or manage dynamically
      const timer = setTimeout(() => {
        loadingBarRef.current.complete();
      }, 3000); // Adjust timing as needed

      // Cleanup timer
      return () => clearTimeout(timer);
    }
  }, []);

  // HANDLE CLIENT CHANGES
  const handleClientChange = (event) => {
    const selectedClientId = event?.target?.value;
    setClientId(selectedClientId);
    dispatch(GetClientSubscription(selectedClientId));
  };

  // HANDLE SUBMIT
  const HandleSubmit = () => {};

  if (!pastCountList || pastCountList[0]?.totalTimeSheetCount === 0) {
    return <div>Loading...</div>;
  }

  const totalTimeSheetCount = pastCountList[0]?.totalTimeSheetCount ?? 0;
  const totalCompletedCount = pastCountList[0]?.totalCompletedCount ?? 0;
  const totalRejectCount = pastCountList[0]?.totalRejectCount ?? 0;
  const approvedPercent = totalTimeSheetCount > 0 ? (totalCompletedCount / totalTimeSheetCount) * 100 : 0;
  const pendingPercent = totalTimeSheetCount > 0 ? (totalRejectCount / totalTimeSheetCount) * 100 : 0;

  // CARD DATA
  const cardData = [
    {
      label: "Total",
      icon: "ri-functions",
      value: totalTimeSheetCount,
      percent: "100",
      status: "up",
    },
    {
      label: "Completed",
      icon: "ri-shield-check-line",
      value: totalCompletedCount,
      percent: approvedPercent?.toFixed(2),
      status: "up",
    },
    {
      label: "Rejected",
      icon: "ri-time-line",
      value: totalRejectCount,
      percent: pendingPercent?.toFixed(2),
      status: "down",
    },
  ]

  // COLUMNS
  const columns = [
    {
      name: "Client/Subscription",
      selector: (row) => (
        <div>
          <h5 className="fs-14 mb-1">
            {row?.clientName}
          </h5>
        </div>
      ),
    },
    {
      name: "Months",
      selector: (row) => row?.months,
    },
    {
      name: "Approver",
      selector: (row) => (
        <Badge
          bg={
            row?.fieldValue === "Pending"
              ? "primary"
              : row?.fieldValue === "Reject"
                ? "danger"
                : "success"
          }
          pill
        >
          {row?.fieldValue}
        </Badge>
      ),
    },

    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex align-items-center gap-2">
          <Button
            variant="outline-dark"
            className="btn-icon"
            onClick={() => handleMonthSheet(row)}
          >
            <i className="ri-eye-line"></i>
          </Button>
        </div>
      ),
    },
  ];

  // HANDLE MOTNH SHEET
  const handleMonthSheet = (row) => {
    setTimesheetData(row); // Update the state with the selected row data
    const data = {
      Id: row.clientId, // Ensure clientId is set
      SubsId: row.subscriptionId, // Ensure subsId is set
      monthId: row.months, // Ensure monthId is set
    };

    dispatch(GetTimeSheetDayWiseBYId(data));

    navigate('/FinanceManager/timesheetsView', {
      state: {
        timesheetData,
        row,
      },
    });
  };

  return (
    <React.Fragment>
      <Header />
     
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <h4 className="main-title mb-0">Past Timesheet</h4>
        </div>
        <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
        <Row className="g-3 mb-3">
          {loading.isLoading ? (
            <ShimmerLoader colNames={["Label", "Value"]} colsCount={cardData.length} />
          ) : (
            cardData.map((card, index) => (
              <Col key={index}>
                <Card className="card-one">
                  <Card.Body>
                    <Card.Title as="label" className="fs-sm fw-medium mb-1">
                      {card.label}
                    </Card.Title>
                    <h3 className="card-value mb-1">
                      <i className={card?.icon}></i> {card?.value}
                    </h3>
                    <small>
                      <span className={`d-inline-flex text-${card?.status === "up" ? "success" : "danger"}`}>
                        {card?.percent}%{" "}
                        <i className={`ri-arrow-${card?.status === "up" ? "up" : "down"}-line`}></i>
                      </span>
                    </small>
                  </Card.Body>
                </Card>
              </Col>
            ))
          )}
        </Row>

        <Card className="card-one">
          <Card.Body>
            {loading.isLoading ? (
              <ShimmerLoader colNames={columns.map((col) => col.name)} colsCount={columns.length} />
            ) : (
              <DataTable columns={columns} data={FinancePastTimeSheettList} pagination />
            )}
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}