import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Form,
  Row,
  Col,
  Card,
  Table,
  Dropdown,
  Image,
  Badge,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AddDayWiseDataById,
  AddTimeSheetStatus,
  GetContincyListById,
  GetMappedContractTypeById,
  GetTimeSheetDayWiseBYId,
} from "../../application/actions/timeSheetAction";
import {
  getContincyListBy,
  getContincyListById,
  getContractTypesById,
  getDayWiseListById,
} from "../../application/selectors/indexSelector";
import ToastifyService from "../_common/ToastifyService";
import Projection from "./projection";
import { useNavigate } from "react-router-dom";
import Header from "../../layouts/Header";
import pdfIcon from "../../assets/img/icons/pdf.png";
import excelIcon from "../../assets/img/icons/excel.png";
import wordIcon from "../../assets/img/icons/word.png";
import LoadingBar from "react-top-loading-bar";

export default function MonthSheetModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [gettimeSheetData, setGettimeSheetData] = useState(null);
  const [data, setData] = useState([]);
  const [editId, setEditId] = useState(null);
  const [forumla, setFormula] = useState(null);
  const [amount, setAmount] = useState(null);
  const [editedRow, setEditedRow] = useState({});
  const [editedata, setEditedata] = useState({});
  const [showProjection, setShowProjection] = useState(false);
  const [projectionData, setProjectionData] = useState(null);
  const DayWiseTimeSheetList = useSelector(getDayWiseListById);
  const ContincyList = useSelector(getContincyListBy);
  const ContractTypes = useSelector(getContractTypesById);
  const [initialFetchData, setInitialFetchData] = useState(null);
  const location = useLocation();
  const rowData = location.state.row || {};
    const [clientName, setClientName] = useState("");
    const [subsname, setSubscriptionName] = useState("");

  useEffect(() => {
    if (rowData) {
      setGettimeSheetData(rowData);
      setData(rowData?.months || "");
      const data = {
        Id: rowData?.clientId,
        SubsId: rowData?.subscriptionId,
        monthId: rowData?.months,
      };
      setInitialFetchData(data);
      dispatch(GetTimeSheetDayWiseBYId(data));
      dispatch(GetMappedContractTypeById(data));
      dispatch(GetContincyListById(rowData?.subscriptionId));
      const [clientName, subsname] = rowData?.clientName?.split(" / ") || [];
      setClientName(clientName);
      setSubscriptionName(subsname);
    }
  }, [rowData]);

  const [totalAmount, setTotalAmount] = useState(0);
  const [baseAmount, setBaseAmount] = useState(0);

  console.log("rowData",rowData)
useEffect(() => {
  // Calculate total amount first
  const calculatedTotalAmount = DayWiseTimeSheetList.reduce((acc, item) => {
    return acc + (Math.round(item?.invoiceAmount) || 0);
  }, 0);

  setTotalAmount(calculatedTotalAmount); // Set the total amount in state

  // Now, calculate base amount based on matching element and contract logic
  if (calculatedTotalAmount !== 0) {
    let calculatedBaseAmount = 0;

    // Find the matching element for Contingency Amount calculation
    if (rowData?.contractName === "Contingency Amount") {
      const matchingElement = ContincyList?.find(
        (element) =>
          calculatedTotalAmount >= element.from && calculatedTotalAmount <= element.to
      );

      // If a matching element is found, calculate the baseAmount based on percentage
      if (matchingElement) {
        calculatedBaseAmount = calculatedTotalAmount * (matchingElement.percentage / 100);
      } else {
        // Apply default percentage if no match is found
        calculatedBaseAmount = calculatedTotalAmount * (10 / 100); // Default 10% if no match
      }
    } else {
      // For other contract types, base amount is simply totalAmount (or apply other logic)
      if (rowData?.contractName === "Transaction") {
        calculatedBaseAmount = calculatedTotalAmount * 1; // For example, 100% of totalAmount
      } else if (rowData?.contractName === "FTE Amount") {
        calculatedBaseAmount = calculatedTotalAmount * 1; // For example, 100% of totalAmount
      } else if (rowData?.contractName === "FlatFee") {
        // Add the flat fee
        DayWiseTimeSheetList.forEach((item) => {
          calculatedBaseAmount += item?.flatFee || 0;
        });
      }
    }

    setBaseAmount(calculatedBaseAmount); // Set the calculated base amount in state
  }

  // Optionally, you can log or perform further actions with `totalAmount` and `baseAmount`
  console.log("Total Amount:", totalAmount);
  console.log("Base Amount:", baseAmount);

}, [DayWiseTimeSheetList, rowData?.contractName]); // Add rowData?.contractName as a dependency


  useEffect(() => {
    if (rowData) {
      switch (rowData?.contractName) {
        case "Transaction Amount":
          setFormula("transactions");
          setAmount(ContractTypes?.transactionRate);
          break;
        case "FTE Amount":
          setFormula("transactions");
          setAmount(ContractTypes?.fteRate);
          break;
        case "Contingency Amount":
          setFormula("clientCollections");
          break;
        case "flatefee":
          setFormula("flatefee");
          break;
        default:
          setFormula(null);
      }
    }
  }, [rowData, ContractTypes?.transactionRate, ContractTypes?.fteRate]);
  const validEntries = DayWiseTimeSheetList.filter(entry => entry.invoiceAmount > 0);
  // HANDLE PROJECTION FUNCTION
  const handleProjectionClick = () => {
    setShowProjection(true);
    setProjectionData(rowData);
    console.log("Get Projection Data...", rowData);
  };

  // HANDLE EDIT FUNCTION
  const handleEditClick = (row) => {
    setEditId(row.id);

    console.log("editId", row);
    setEditedRow({ ...row });
  };

  // HANDLE SAVE FUNCTION
  const handleSaveClick = async () => {
    const data1 = {
      id: editedRow.id,
      transactions: editedRow.transactions || 0,
      ClientCollections: editedRow.ClientCollections || 0,
      FTEDeployed: editedata.FTEDeployed || 0,
      TransactionAmount: editedata.TransactionAmount || 0,
      FTEAmount: editedata.FTEAmount || 0,
      ContingencyAmount: editedata.ContingencyAmount || 0,
      InvoiceAmount: editedata.invoiceAmount || 0,
    };

    try {
      dispatch(AddDayWiseDataById({ data1, initialFetchData }));
      setEditId(null);
      ToastifyService.success("Updated Timesheet!");
    } catch (error) {
      console.error("Error saving data:", error);
      ToastifyService.error("Failed to update Timesheet!");
    }
  };

  const handleInputChange = (e, field) => {
    debugger;
    const value = parseFloat(e.target.value); // Ensure a number is being used
    setEditedRow((prevState) => {
      debugger;
      const updatedRow = { ...prevState, [field]: value };
      let invoiceAmount = updatedRow.invoiceAmount;
      if (field === "transactions") {
        if (rowData?.contractName === "Transaction Amount") {
          invoiceAmount = value * 2; // example multiplication factor
          setEditedata({ invoiceAmount });
        } else if (rowData?.contractName === "FTE Amount") {
          const fteDeployed = value / (ContractTypes?.transactionTarget || 1);
          invoiceAmount = fteDeployed * (amount || 1);
          updatedRow.fteDeployed = fteDeployed;
          setEditedata({ FTEDeployed: fteDeployed, invoiceAmount });
        }
      }

      if (field === "clientCollections") {
        if (rowData?.contractName === "Contingency Amount") {
          invoiceAmount = value;
          updatedRow.contingencyAmount = value;
          setEditedata({ invoiceAmount, ContingencyAmount: value });
        }
      }

      if (field === "flatfee") {
        invoiceAmount = value;
        updatedRow.flatFee = value;
        setEditedata({ invoiceAmount, flatFee: value });
      }
      updatedRow.invoiceAmount = invoiceAmount;
      return updatedRow;
    });
  };

  // HANDLE SUBMIT FUCTION
  const handelSubmit = () => {
    const data = {
      clientid: rowData?.clientId,
      subscriptionid: rowData?.subscriptionId,
      months: rowData?.months,
    };
    dispatch(AddTimeSheetStatus(data));
    ToastifyService.success(
      "operational reviewer Timesheet Successfully Submitted!"
    );
  };

  // PROGRESS TOP LOADER
  const loadingBarRef = useRef(null);
  useEffect(() => {
    if (loadingBarRef.current) {
      // Start the loading bar
      loadingBarRef.current.continuousStart();

      // Simulate a load completion or manage dynamically
      const timer = setTimeout(() => {
        loadingBarRef.current.complete();
      }, 3000); // Adjust timing as needed

      // Cleanup timer
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <React.Fragment>
      <Header />
      <Projection
        show={showProjection}
        closeFunction={() => setShowProjection(false)}
        data={projectionData}
      />
      <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
      <div className="main main-app manage-item-page p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/OpsReviewer/timesheets">Timesheet</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Timesheet View
              </li>
            </ol>
            <h4 className="main-title mb-0">{`${rowData?.months}`}</h4>
          </div>
          <div className="d-flex gap-2">
            <Button
              type="submit"
              variant="primary"
              className="fs-14 d-flex align-items-center"
              onClick={handelSubmit}
            >
              <i className="ri-add-line fs-18 lh-1 align-middle"></i>
              <span className="align-middle">Submit</span>
            </Button>
            <Button variant="dark" onClick={handleProjectionClick}>
              Projection
            </Button>
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-primary"
                className="btn-icon custom-dropdown-action wt-35 ht-35"
              >
                <i className="ri-more-2-fill fs-18"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#">
                  <i className="ri-file-copy-line"></i> Upload Document
                </Dropdown.Item>
                <Dropdown.Item href="#">
                  <i className="ri-file-copy-line"></i> View Contract
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <Row>
          {/* TIMESHEET TABLE VIEW */}
          <Col md={8}>
            <Card className="card-one h-auto mb-3">
              <Card.Body>
                <div className="d-flex flex-wrap justify-content-between gap-5">
                  <div>
                    <h6 className="fs-14 fw-bold mb-1">{`${rowData?.clienName}`}</h6>
                    <Form.Label>Client Name</Form.Label>
                  </div>
                  <div>
                    <h6 className="fs-14 fw-bold mb-1">{`${rowData?.subsName}`}</h6>
                    <Form.Label>Subscription</Form.Label>
                  </div>
                  <div>
                    <h6 className="fs-14 fw-bold mb-1">{`${rowData?.months}`}</h6>
                    <Form.Label>Month</Form.Label>
                  </div>

                  <div>
                    <h6 className="fs-14 fw-bold mb-1">{validEntries?.length}</h6>
                    <Form.Label>Number of days Entry</Form.Label>
                  </div>
                  <div>
                    <div>
                      <Badge bg="primary" className="mb-1" pill>
                        Ongoing
                      </Badge>
                    </div>
                    <Form.Label>Timesheet Status</Form.Label>
                  </div>
                </div>
              </Card.Body>
            </Card>

            <Card className="card-one timesheet-table-card-height">
              <Card.Body className="overflow-y custom-scroll">
                <Table className="mb-0" responsive>
                  <thead>
                    <tr>
                      <th>Day</th>
                      {/* Conditionally render headers based on contractName */}
                      {rowData?.contractName === "Contingency Amount" ||
                      rowData?.contractName === "flatefee" ? (
                        <th>Client Collections</th>
                      ) : null}

                      {rowData?.contractName === "Transaction Amount" ||
                      rowData?.contractName === "FTE Amount" ? (
                        <>
                          <th>Transactions</th>
                          <th>FTE Deployed</th>
                        </>
                      ) : null}

                      <th>Invoice Amount</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {DayWiseTimeSheetList.map((item, index) => {
                      const isWeekend =
                        item.daysWise === "Saturday" ||
                        item.daysWise === "Sunday";
                      return (
                        <tr
                          key={index}
                          style={
                            isWeekend ? { backgroundColor: "#d3d3d3" } : {}
                          }
                        >
                          <td>{item.daysWise}</td>
                          {rowData?.contractName === "Transaction Amount" ||
                          rowData?.contractName === "FTE Amount" ? (
                            <>
                              <td>
                                {item.id === editId && !isWeekend ? (
                                  <Form.Control
                                    type="number"
                                    value={editedRow?.transactions || ""}
                                    onChange={(e) =>
                                      handleInputChange(e, "transactions")
                                    }
                                  />
                                ) : (
                                  item.transactions
                                )}
                              </td>
                              <td>
                                <Form.Control
                                  type="number"
                                  value={
                                    item.id === editId
                                      ? editedRow?.fteDeployed
                                      : item?.fteDeployed
                                  }
                                  disabled
                                />
                              </td>
                            </>
                          ) : null}

                          {rowData?.contractName === "Contingency Amount" ? (
                            <>
                              <td>
                                {item.id === editId ? (
                                  <Form.Control
                                    type="number"
                                    value={editedRow?.contingencyAmount}
                                    onChange={(e) =>
                                      handleInputChange(e, "clientCollections")
                                    }
                                  />
                                ) : (
                                  item.contingencyAmount
                                )}
                              </td>
                            </>
                          ) : null}
                          {rowData?.contractName === "flatfee" ? (
                            <>
                              <td>
                                {item.id === editId ? (
                                  <Form.Control
                                    type="number"
                                    value={editedRow?.flatFee}
                                    onChange={(e) =>
                                      handleInputChange(e, "flatfee")
                                    }
                                  />
                                ) : (
                                  item.flatFee
                                )}
                              </td>
                            </>
                          ) : null}

                          {/* <td>
                            <Form.Control
                              type="text"
                              value={amount || "0"}
                              disabled
                            />
                          </td> */}
                          <td>
                            <Form.Control
                              type="number"
                              value={
                                item.id === editId
                                  ? editedRow?.invoiceAmount
                                  : item?.invoiceAmount
                              }
                              disabled
                            />
                          </td>
                          <td>
                            {item.id === editId ? (
                              <Button
                                variant="success"
                                onClick={handleSaveClick}
                                disabled={isWeekend}
                              >
                                Save
                              </Button>
                            ) : (
                              <Button
                                variant="primary"
                                className="btn-icon"
                                onClick={() => handleEditClick(item)}
                                disabled={isWeekend}
                              >
                                <i className="ri-pencil-fill"></i>
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th className="bg-gray-200">Total</th>
                      {rowData?.contractName === "Transaction Amount" ||
                      rowData?.contractName === "FTE Amount" ? (
                        <>
                          <th className="bg-gray-200">
                            {DayWiseTimeSheetList.reduce(
                              (acc, item) => acc + (item?.transactions || 0),
                              0
                            )}
                          </th>
                          <th className="bg-gray-200">
                            {DayWiseTimeSheetList.reduce(
                              (acc, item) =>
                                acc + (Math.round(item?.fteDeployed) || 0),
                              0
                            )}
                          </th>
                        </>
                      ) : null}
 {rowData?.contractName === "Contingency Amount" ? (
                            <>
                              <th>
                              {DayWiseTimeSheetList.reduce(
                              (acc, item) => acc + (item?.contingencyAmount || 0),
                              0
                            )}
                                 
                              </th>
                            </>
                          ) : null}
                          {rowData?.contractName === "flatFee" ? (
                            <>
                              <th>
                              {DayWiseTimeSheetList.reduce(
                              (acc, item) => acc + (item?.flatFee || 0),
                              0
                            )}
                                 
                              </th>
                            </>
                          ) : null}
                      
                      {/* <th className="bg-gray-200">
                        {DayWiseTimeSheetList.reduce(
                          (acc, item) => acc + (Math.round(item?.invoiceAmount) || 0),
                          0
                        )}
                      </th> */}
                      <th className="bg-gray-200">{baseAmount}</th>

                    </tr>
                  </tfoot>
                </Table>
              </Card.Body>
            </Card>
          </Col>

          {/* UPLOADED DOCUMENT FILE */}
          <Col md={4}>
            <Card className="card-one h-auto mb-3">
              <Card.Header>Uploaded Document</Card.Header>
              <Card.Body>
                <Table>
                  <tbody>
                    {[
                      {
                        icon: pdfIcon,
                        name: "PDF File 2023",
                      },

                      {
                        icon: excelIcon,
                        name: "Excel File 2023",
                      },

                      {
                        icon: wordIcon,
                        name: "Word File 2023",
                      },
                    ].map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div>
                            {/* Use item.icon instead of item.pdfIcon */}
                            <Image src={item.icon} alt="icon" />
                            <span className="ms-2">{item.name}</span>
                          </div>
                        </td>
                        <td className="w-0">
                          <Button variant="dark" className="btn-icon">
                            <i className="ri-eye-line"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <Card className="card-one h-auto">
              <Card.Header>Contract Document</Card.Header>
              <Card.Body>
                <Table>
                  <tbody>
                    {[
                      {
                        icon: pdfIcon,
                        name: "PDF File 2023",
                      },

                      {
                        icon: pdfIcon,
                        name: "PDF File 2023",
                      },

                      {
                        icon: pdfIcon,
                        name: "PDF File 2023",
                      },
                    ].map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div>
                            {/* Use item.icon instead of item.pdfIcon */}
                            <Image src={item.icon} alt="icon" />
                            <span className="ms-2">{item.name}</span>
                          </div>
                        </td>
                        <td className="w-0">
                          <Button variant="dark" className="btn-icon">
                            <i className="ri-eye-line"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
