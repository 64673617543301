import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Row,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo/main-logo.png";
import pageSvg from "../../assets/svg/forgot_password.svg";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
// import pageSvg from "../../assets/svg/forgot_password.svg";

const emailRegex =
  /^(?!.*\.{2})(?!.*@.*@)[a-zA-Z0-9](?!.*@\.)(?!.*\.@)[a-zA-Z0-9._%+-]*[a-zA-Z0-9]@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?<!\.)$/;
const passwordRegex =
  /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%?=*&]).{8,20})/;

const OTPInput = ({ numInputs, value, onChange }) => {
  const handleInputChange = (e, index) => {
    const val = e.target.value;
    if (/^[0-9]$/.test(val) || val === "") {
      const newValue = value.split("");
      newValue[index] = val;
      onChange(newValue.join(""));
      if (val !== "" && e.target.nextSibling) {
        e.target.nextSibling.focus();
      }
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {Array.from({ length: numInputs }).map((_, index) => (
        <input
          key={index}
          type="text"
          maxLength={1}
          value={value[index] || ""}
          onChange={(e) => handleInputChange(e, index)}
          style={{
            width: "40px",
            height: "40px",
            margin: "0 5px",
            fontSize: "18px",
            borderRadius: "4px",
            border: "1px solid lightgray",
            textAlign: "center",
          }}
        />
      ))}
    </div>
  );
};

export default function ForgotPasswordComponent() {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [OTP, setOTP] = useState("");
  const { setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  document.body.classList.remove("sidebar-show");

  const validateEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailPattern.test(email)) {
      setEmailError("Please enter a valid email address.");
      return false;
    }
    setEmailError("");
    return true;
  };

  const handleSendOTP = async () => {
    const isValid = validateEmail();
    if (isValid) {
      try {
        const response = await axiosPrivate.post("Auth/Forget-password", {
          email,
        });
        if (response.data.status) {
          alert("OTP sent to your email.");
          setStep(2);
        } else {
          setEmailError(response.data.message || "Failed to send OTP.");
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
        setEmailError("Something went wrong. Please try again.");
      }
    }
  };

  const handleVerifyOTP = async () => {
    if (OTP.length === 6) {
      try {
        const response = await axiosPrivate.post("Auth/Otp_Verify", {
          username: email,
          otp: parseInt(OTP, 10),
        });
        if (response.data.status) {
          setStep(3);
        } else {
          setOtpError("Invalid OTP. Please try again.");
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
        setOtpError("Something went wrong. Please try again.");
      }
    } else {
      setOtpError("Please enter a valid 6-digit OTP.");
    }
  };

  const [formData, setFormData] = useState({
    email: email,
    password: "",
    confirmPassword: "",
  });

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const handleNewPassword = async (e) => {
    e.preventDefault();
    const { password, confirmPassword } = formData;
    if (!passwordRegex.test(password)) {
      setPasswordError(
        "Password must be at least 8 characters long, contain 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
      );
      return;
    }
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }
    const data = {
      email: email,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
    };

    try {
      const response = await axiosPrivate.post("Auth/reset-password", data);
      if (response?.status === 200) {
        const {
          accessToken,
          refreshToken,
          roles,
          id,
          isResetDefaultPass,
          name,
        } = response?.data;

        const userName = email;
        setAuth({
          userName,
          password: formData.password,
          roles,
          accessToken,
          refreshToken,
          id,
          isResetDefaultPass,
          name,
        });
        if (roles?.includes("Admin")) {
          navigate("/dashboard/manage");
        }  else if (roles[0] === "Operations Reviewer") {
          navigate("/OpsReviewer/timesheets");
        } else if (roles[0] === "Operations Approver") {
          navigate("/OpsApproval/timesheets");
        } else if (roles[0] === "Finanace Manager") {
          navigate("/FinanceManager/timesheets");
        } else {
          navigate("/dashboard/manage");
        }
      } else {
        setPasswordError(response.data || "Failed to reset password.");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      setPasswordError("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          <Link to="/account/login" className="header-logo">
            IClaim Invoive{" "}
          </Link>
          <Nav className="nav-icon">
            <Nav.Link href="">
              <i className="ri-twitter-fill"></i>
            </Nav.Link>
            <Nav.Link href="">
              <i className="ri-github-fill"></i>
            </Nav.Link>
            <Nav.Link href="">
              <i className="ri-dribbble-line"></i>
            </Nav.Link>
          </Nav>
        </Container>
      </div>

      <div className="content">
        <Container>
          {step === 1 ? (
            <Card className="card-auth">
              <Card.Body className="text-center">
                <div className="mb-5">
                  <object
                    type="image/svg+xml"
                    data={pageSvg}
                    className="w-50"
                    aria-label="svg image"
                  ></object>
                </div>
                <Card.Title>Reset your password</Card.Title>
                <Card.Text className="mb-5">
                  Enter your email address and we will send you an OTP to verify
                  your email.
                </Card.Text>

                <Row className="g-2 justify-content-center">
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Enter email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={validateEmail}
                    />
                    {emailError && (
                      <Alert variant="danger" className="mt-2">
                        {emailError}
                      </Alert>
                    )}
                  </Col>
                  <Col sm="4">
                    <Button variant="primary" onClick={handleSendOTP}>
                      Send OTP
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ) : step === 2 ? (
            <Card className="card-auth">
              <Card.Body className="text-center">
                <div className="mb-5">
                  <object
                    type="image/svg+xml"
                    data={pageSvg}
                    className="w-50"
                    aria-label="svg image"
                  ></object>
                </div>
                <Card.Title>Verify OTP</Card.Title>
                <Card.Text className="mb-5">
                  Enter the OTP you received on your email.
                </Card.Text>

                <Row className="justify-content-center">
                  <Col sm="12">
                    <OTPInput
                      numInputs={6}
                      value={OTP}
                      onChange={(val) => setOTP(val)}
                    />
                    {otpError && (
                      <Alert variant="danger" className="mt-2">
                        {otpError}
                      </Alert>
                    )}
                  </Col>
                </Row>
                <Button
                  variant="primary"
                  className="mt-3"
                  onClick={handleVerifyOTP}
                >
                  Verify OTP
                </Button>
              </Card.Body>
            </Card>
          ) : (
            <Card className="card-auth">
              <Card.Body className="text-center">
                <div className="mb-4">
                  <object
                    type="image/svg+xml"
                    data={pageSvg}
                    className="w-50"
                    aria-label="svg image"
                  ></object>
                </div>
                <Card.Title>Set New Password</Card.Title>
              </Card.Body>

              <Card.Body>
                <Form onSubmit={handleNewPassword}>
                  <Form.Group>
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type="password"
                      value={formData.password}
                      onChange={(e) =>
                        setFormData({ ...formData, password: e.target.value })
                      }
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      value={formData.confirmPassword}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          confirmPassword: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  {passwordError && (
                    <Alert variant="danger">{passwordError}</Alert>
                  )}
                  <Button type="submit" className="mt-3">
                    Set New Password
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          )}
        </Container>
      </div>
    </div>
  );
}
