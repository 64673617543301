import React, { useState, useRef, useEffect } from "react";
import Header from "../../layouts/Header";
import { Card, Col, Row } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import axios from "../../api/axios";
import LoadingBar from 'react-top-loading-bar';

export default function Dashboard() {
  const [fileData, setFileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchFile = async (despositionNumber, nodeNumber) => {
    setLoading(true);
    setError(null);
    try {
        const response = await fetch(`https://localhost:44306/api/File/PDF/Eligibility%20Verification/8706e139-8b86-4ee8-95ca-baa6080a0d9b/8706e139-8b86-4ee8-95ca-baa6080a0d9b`);
        
        if (!response.ok) {
            throw new Error('File not found');
        }
        const fileData = URL.createObjectURL(await response.blob());
        setFileData(fileData);
    } catch (error) {
        setError(error.message);
    } finally {
        setLoading(false);
    }
};

  var data = [[0, 9], [1, 7], [2, 4], [3, 8], [4, 4], [5, 12], [6, 4], [7, 6], [8, 5], [9, 10], [10, 4], [11, 5], [12, 10], [13, 2], [14, 6]];

  const chart = {
    parentHeightOffset: 0,
    stacked: true,
    sparkline: {
      enabled: true
    }
  };

  const states = {
    hover: {
      filter: {
        type: 'none'
      }
    },
    active: {
      filter: {
        type: 'none'
      }
    }
  };

  const plotOptions = {
    bar: {
      columnWidth: '60%'
    },
  };

  const stroke = {
    curve: 'straight',
    lineCap: 'square'
  };

  const invoiceAmtClient = [{
    type: 'column',
    data: [[0, 0], [1, 0], [2, 5], [3, 10], [4, 6], [5, 10], [6, 15], [7, 18], [8, 7], [9, 11], [10, 13], [11, 15], [12, 13], [13, 7], [14, 5]]
  }, {
    type: 'column',
    data: data
  }];

  const optionInvouceAmt = {
    chart: chart,
    states: states,
    colors: ['#506fd9', '#e5e9f2'],
    plotOptions: plotOptions,
    stroke: stroke,
    fill: { opacity: 1 },
    tooltip: { enabled: false }
  };

   // PROGRESS TOP LOADER
   const loadingBarRef = useRef(null);
   useEffect(() => {
       if (loadingBarRef.current) {
           // Start the loading bar
           loadingBarRef.current.continuousStart();

           // Simulate a load completion or manage dynamically
           const timer = setTimeout(() => {
               loadingBarRef.current.complete();
           }, 3000); // Adjust timing as needed

           // Cleanup timer
           return () => clearTimeout(timer);
       }
   }, []);

  return (
    <React.Fragment>
      <Header />
      <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Dashboard</h4>
          </div>
        </div>

        <Row className="g-3">
          {[
            {
              "label": "Pending Approval",
              "icon": "ri-shopping-bag-3-line",
              "value": "48",
              "percent": "0.7",
              "status": "down"
            }, {
              "label": "Rejected Approver",
              "icon": "ri-briefcase-4-line",
              "value": "88",
              "percent": "2.1",
              "status": "up"
            }, {
              "label": "Pending - Finance",
              "icon": "ri-inbox-line",
              "value": "75",
              "percent": "0.3",
              "status": "down"
            }
          ].map((card, index) => (
            <Col xs="6" xl="4" key={index}>
              <Card className="card-one">
                <Card.Body>
                  <Card.Title as="label" className="fs-sm fw-medium mb-1">{card.label}</Card.Title>
                  <h3 className="card-value mb-1"><i className={card.icon}></i> {card.value}</h3>
                  <small><span className={"d-inline-flex text-" + ((card.status === "up") ? "success" : "danger")}>{card.percent}% <i className={"ri-arrow-" + ((card.status === "up") ? "up" : "down") + "-line"}></i></span> than last week</small>
                </Card.Body>
              </Card>
            </Col>
          ))}


          <Col md={6} xl={6} sm={12}>
            <Card className="card-one">
              <Card.Body className="p-3">
                <Row className="align-items-center">
                  <Col xs="7">
                    <h3 className="card-value mb-1">Invoice Amount - Client</h3>
                    <label className="card-title fw-medium text-dark mb-1">Client Wise – Last 3 Months</label>
                    <span className="d-block text-muted fs-11 ff-secondary lh-4">Client, Date Range</span>
                  </Col>
                  <Col xs="5">
                    <ReactApexChart series={invoiceAmtClient} options={optionInvouceAmt} type="bar" height={120} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>


          <Col md={6} xl={6} sm={12}>
            <Card className="card-one">
              <Card.Body className="p-3">
                <Row className="align-items-center">
                  <Col xs="7">
                    <h3 className="card-value mb-1">Invoice Amount - Cost Center</h3>
                    <label className="card-title fw-medium text-dark mb-1">Cost Center Amount – Last 3 Months</label>
                    <span className="d-block text-muted fs-11 ff-secondary lh-4">Cost Center, Date Range</span>
                  </Col>
                  <Col xs="5">
                    <ReactApexChart series={invoiceAmtClient} options={optionInvouceAmt} type="bar" height={120} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>


          <Col md={6} xl={6} sm={12}>
            <Card className="card-one">
              
            </Card>
          </Col>
        </Row>
        <div>
    <button onClick={() => fetchFile(111, 111)}>Get File</button>

    {loading && <p>Loading...</p>}
    {error && <p>{error}</p>}

    {fileData && (
        <div>
            <h3>File Preview</h3>
            {/* Use img tag to display the image */}
            <img src={fileData} alt="File preview" width="600" />
        </div>
    )}
</div>
      </div>
    </React.Fragment>
  )
}