import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import UserRoles from "./UserRole/user-role";
import UserDirectory from "./UserDirectory/user-directory";
import { GetRole } from "../../../application/actions/usersAction";
import { useDispatch } from "react-redux";
import LoadingBar from "react-top-loading-bar";

export default function UserSetup() {
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState('user_role_tab');
    const handleTabSelect = (tab) => {
        dispatch(GetRole);
        setActiveTab(tab);
    };

    // PROGRESS TOP LOADER
    const loadingBarRef = useRef(null);
    useEffect(() => {
        if (loadingBarRef.current) {
            // Start the loading bar
            loadingBarRef.current.continuousStart();

            // Simulate a load completion or manage dynamically
            const timer = setTimeout(() => {
                loadingBarRef.current.complete();
            }, 3000); // Adjust timing as needed

            // Cleanup timer
            return () => clearTimeout(timer);
        }
    }, []);

    return (
        <React.Fragment>
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="d-md-flex align-items-center justify-content-between mb-3">
                <h3 className="fs-14 mb-1">User Setup</h3>
            </div>

            <Tabs id="custom-styled-tabs" activeKey={activeTab} onSelect={handleTabSelect} className="common-in-tabs bg-transparent">
                <Tab eventKey="user_role_tab" title={<>User Role</>} tabClassName="custom-tab-header fw-semibold">
                    <div className="custom-tab-body pt-3">
                        <UserRoles />
                    </div>
                </Tab>
                <Tab eventKey="user_directory_tab" title={<>User Directory</>} tabClassName="custom-tab-header fw-semibold">
                    <div className="custom-tab-body pt-3">
                        <UserDirectory />
                    </div>
                </Tab>
            </Tabs>
        </React.Fragment>
    )
};