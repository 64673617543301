import axios from "axios";
import Environment from "../../../core/Environment";

const GetCommonListById = async () => {
    
  try {
    const response = await axios.get(`${Environment.Common_Url}Get-CommonListById`);
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};
const GetContract = async () => {
    
  try {
    const response = await axios.get(`${Environment.Common_Url}Get-ContractType`);
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};
const sendMail = async (data) => {
  try {
    const response = await axios.get(`${Environment.Common_Url}sendEmail/${data.file}/${data.toEmail}/${data.subject}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};

export default {
    GetCommonListById,GetContract,sendMail
};
