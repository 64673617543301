import React, { useEffect } from "react";
import { Badge, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getRolesData } from "../../../../application/selectors/indexSelector";
import { GetRole } from "../../../../application/actions/usersAction";

export default function UserRoles() {
    const dispatch = useDispatch()
    const RoletList = useSelector(getRolesData);
    useEffect(() => {
        dispatch(GetRole);
    }, []);

    return (
        <React.Fragment>
            <div className="d-md-flex align-items-center justify-content-between mb-3">
                <div>
                    <h4 className="main-title fs-14 mb-0">List of User Role</h4>
                </div>
            </div>
            <Card className="card-one">
                <Card.Body>
                    <Table className="mb-0" responsive>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>User Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {RoletList.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.role_name}</td>
                                    <td><Badge bg="primary" pill>{item.user_count}</Badge></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}