import { useRef, useState, useEffect, React } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Row, Col, Button, Form } from "react-bootstrap";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import LoadingSpinner from "../_common/LoadingSpinner";
const LOGIN_URL = "https://arinvoicegateway.cxengine.net/api/Auth/login";
//const LOGIN_URL = "https://localhost:44306/api/Auth/login";
export default function LoginComponent() {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const userRef = useRef();
  const errRef = useRef();
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    debugger;
    e.preventDefault();
    try {
      setLoading({
        isLoading: true,
        value: "login",
      });
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ username: user, password: pwd }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      debugger;
      sessionStorage.setItem("auth", JSON.stringify(response?.data));
      const accessToken = response?.data?.accessToken;
      const refreshToken = response?.data?.refreshToken;
      const roles = response?.data?.roles;
      const id = response?.data?.id;
      const isResetDefaultPass = response?.data?.isResetDefaultPass;
      const name = response?.data?.name;
      setAuth({
        user,
        pwd,
        roles,
        accessToken,
        refreshToken,
        id,
        isResetDefaultPass,
        name,
      });
      setUser("");
      setPwd("");
debugger
      //AAA check later
      if (!isResetDefaultPass) {
        navigate("/account/reset-password");
      } else if (roles[0] === "Operations Reviewer") {
        navigate("/OpsReviewer/timesheets");
      } else if (roles[0] === "Operations Approver") {
        navigate("/OpsApproval/timesheets");
      } else if (roles[0] === "Finanace Manager") {
        navigate("/FinanceManager/timesheets");
      } else {
        navigate("/dashboard/manage");
      }

      // navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    } finally {
      setLoading({
        isLoading: false,
        value: "login",
      });
    }
  };

  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="4" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              <Card.Title>Login</Card.Title>
              <Card.Text className="text-dark">
                Welcome back! Please login to continue.
              </Card.Text>
            </Card.Header>
            <Card.Body>
              <Form>
                <div className="mb-4">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your Username"
                    name="username"
                    id="username"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    required
                  />
                </div>
                <div className="mb-4">
                  <Form.Label className="d-flex justify-content-between">
                    Password
                  </Form.Label>
                  <Form.Control
                    name="password"
                    placeholder="Enter your password"
                    type="password"
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                  />
                </div>
                <p
                  ref={errRef}
                  className={errMsg ? "errmsg" : "offscreen"}
                  aria-live="assertive"
                >
                  {errMsg}
                </p>
                <Button
                  type="button"
                  className="btn-sign"
                  disabled={loading.isLoading && loading?.value === "login"}
                  onClick={handleSubmit}
                >
                  {loading.isLoading && loading?.value === "login" ? (
                    <LoadingSpinner
                      color="#ffffff"
                      size={30}
                      type={"TailSpin"}
                    />
                  ) : (
                    "Let me in"
                  )}
                </Button>
                <div className="text-end mt-1">
                  <Link to="/account/forgot-password" className="float-end">
                    Forgot password?
                  </Link>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col className="d-none d-lg-block position-relative"></Col>
      </Row>
    </div>
  );
}
