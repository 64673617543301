import React, { useEffect, useState } from "react";
import { Offcanvas, Form, Badge } from "react-bootstrap";

export default function AccessLevelView(props) {
    const [getAccessLevelData, setGetAccessLevelData] = useState(null);
    useEffect(() => {
        if (props.data) {
            setGetAccessLevelData(props.data);
        }
    }, [props.data]);

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Access Level View</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {getAccessLevelData ? (
                        <>
                            <div className="product-wrapper p-2 mb-3">
                                <Form.Label className="fs-12 mb-1">Status</Form.Label>
                                <h6 className="fs-14 fw-semibold">
                                    <Badge bg={`${getAccessLevelData.status == "Active" ? "success" : "danger"}`} pill>{getAccessLevelData.status}</Badge>
                                </h6>
                            </div>

                            <div className="product-wrapper p-2 mb-3">
                                <Form.Label className="fs-12 mb-1">User Group</Form.Label>
                                <div className="d-flex flex-wrap gap-1 ">
                                    {getAccessLevelData.groupName ? (
                                        getAccessLevelData.user_group.map((name, index) => (
                                            <Badge key={index} bg="primary" pill>{name}</Badge>
                                        ))
                                    ) : (
                                        <span>No Groups</span>
                                    )}
                                </div>
                            </div>

                            <div className="product-wrapper p-2 mb-3">
                                <Form.Label className="fs-12 mb-1">User Count</Form.Label>
                                <h6 className="fs-14 fw-semibold">
                                    <Badge bg="primary" pill>
                                        {getAccessLevelData.user_count || 0}
                                    </Badge>
                                </h6>
                            </div>

                            <div className="product-wrapper p-2 mb-3">
                                <Form.Label className="fs-12 mb-1">DOB</Form.Label>
                                <h6 className="fs-14 fw-semibold">{getAccessLevelData.dob}</h6>
                            </div>
                        </>
                    ) : (
                        <p>Loading...</p>
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    );
}
