import React, { useEffect, useRef } from "react";
import Header from "../../layouts/Header";
import { Link } from "react-router-dom";
import "./invoice-template.scss";
import DefaultTemplates from "./default-template";
import LoadingBar from 'react-top-loading-bar';

export default function InvoiceTemplate() {
    // PROGRESS TOP LOADER
    const loadingBarRef = useRef(null);
    useEffect(() => {
        if (loadingBarRef.current) {
            // Start the loading bar
            loadingBarRef.current.continuousStart();

            // Simulate a load completion or manage dynamically
            const timer = setTimeout(() => {
                loadingBarRef.current.complete();
            }, 3000); // Adjust timing as needed

            // Cleanup timer
            return () => clearTimeout(timer);
        }
    }, []);
    return (
        <React.Fragment>
            <Header />
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0">Invoice Templates</h4>
                    </div>
                </div>

                <div className="d-flex flex-wrap gap-3">
                    <Link to="/invoicetemplate/manage/create" className="profile-template-view create-new">
                        <div className="new-template-wrapper">
                            <i className="ri-add-line fs-28"></i>
                            <h6 className="fs-14 fw-semibold text-uppercase">New Template</h6>
                        </div>
                    </Link>

                    <DefaultTemplates />
                </div>
            </div>
        </React.Fragment>
    )
}